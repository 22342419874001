import { apiClient } from 'api/apiClient';

const RESOURCE = '/remarks';

export interface IRemarks {
	code: string;
	remarks1: string;
	shortremark: string;
	remarksrtf: string;
	ssmaTimeStamp: string;
}

export const getRemarks = async (): Promise<IRemarks[]> => {
	const { data } = await apiClient.get(`${RESOURCE}`);

	return data;
};
