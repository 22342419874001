import { useQuery } from '@tanstack/react-query';
import { getTemporaryReconciliationsDeposits } from 'api/resources/temporaryReconciliations';
import type { ITemporaryReconciliationDeposit } from 'api/resources/temporaryReconciliations';
import { ECacheKeys } from 'cache/CacheKeys';
import type { UseQueryOptions } from '@tanstack/react-query';

export const useGetTemporaryReconciliationsDeposits = (
	filter: string,
	options?: UseQueryOptions<ITemporaryReconciliationDeposit[]>
) => {
	const query = useQuery<ITemporaryReconciliationDeposit[]>({
		queryKey: [ECacheKeys.TemporaryReconciliations, 'Deposits', filter],
		queryFn: () => getTemporaryReconciliationsDeposits(filter),
		refetchInterval: false,
		refetchOnWindowFocus: false,
		...(options || {}),
	});

	return query;
};
