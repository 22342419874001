import React, { useCallback, useMemo, useState } from 'react';
import type { ITemporaryReconciliationCheck } from 'api/resources/temporaryReconciliations';
import { useCheckbookReconcile } from './hooks/useCheckbookReconcile';
import {
	ColumnDef,
	ColumnFiltersState,
	Row as TTableRow,
} from '@tanstack/react-table';
import Table from 'legacy/app/components/newTable/Table';
import { formatDate } from 'legacy/utilities/formatDate';
import { formatCurrency } from 'legacy/utilities/formatCurrency';
import { CheckbookReconcileCheckboxCell } from './CheckbookReconcileCheckboxCell';
import { CheckbookReconcileCheckboxHeader } from './CheckbookReconcileCheckboxHeader';

const CheckbookReconcileChecksTable = () => {
	const {
		tab,
		outstandingChecks,
		clearedChecks,
		isGetChecksLoading,
		selectedChecks,
		isSearchEnabled,
		handleOnCheckSelectedChange,
		checksSelectAll,
		handleOnChecksSelectAll,
	} = useCheckbookReconcile();

	const isOutstandingTab = tab === 'outstanding';

	const handleOnChange = useCallback(
		async (
			e: React.ChangeEvent<HTMLInputElement>,
			row?: TTableRow<ITemporaryReconciliationCheck>
		) => {
			if (!row || !row.id) return;
			await handleOnCheckSelectedChange(Number(row.id), e.target.checked);
		},
		[handleOnCheckSelectedChange]
	);

	const handleOnSelectAll = useCallback(
		async (e: React.ChangeEvent<HTMLInputElement>) => {
			handleOnChecksSelectAll(e.target.checked);
		},
		[handleOnChecksSelectAll]
	);

	const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

	const columnFilterState = {
		columnFilters,
	};

	const columns = useMemo<ColumnDef<ITemporaryReconciliationCheck>[]>(
		() => [
			{
				id: 'cleared',
				header: () =>
					CheckbookReconcileCheckboxHeader({
						handleOnChange: handleOnSelectAll,
						checked: isOutstandingTab
							? checksSelectAll.outstanding
							: checksSelectAll.cleared,
						disabled: isOutstandingTab
							? outstandingChecks.length === 0
							: clearedChecks.length === 0,
					}),
				size: 25,
				cell: ({ row }) =>
					CheckbookReconcileCheckboxCell({
						row,
						handleOnChange,
						checked: selectedChecks[row.id],
					}),
				enableColumnFilter: false,
			},
			{
				accessorKey: 'checknum',
				header: 'Check #',
				size: 50,
				cell: (info) => info.getValue(),
			},

			{
				accessorFn: (col) => formatDate(col.userdate ?? ''),
				header: 'Date',
				size: 50,
				cell: (info) => info.getValue(),
			},
			{
				accessorFn: (col) => formatCurrency(col.amount ?? 0),
				header: 'Amount',
				size: 50,
				cell: (info) => info.getValue(),
			},
			{
				accessorKey: 'supplier',
				header: 'Payee',
				size: 50,
				cell: (info) => info.getValue(),
			},
		],
		[
			selectedChecks,
			handleOnChange,
			checksSelectAll,
			handleOnSelectAll,
			isOutstandingTab,
			clearedChecks,
			outstandingChecks,
		]
	);

	return (
		<div>
			<div className="tw-grid tw-grid-flow-col tw-items-center tw-justify-between tw-bg-[#eeede9] tw-p-4 tw-border tw-rounded-md">
				<span className="tw-font-bold tw-text-lg">Checks</span>
			</div>
			<div className="tw-h-96 tw-overflow-auto">
				<Table
					columns={columns}
					isLoading={isGetChecksLoading}
					state={columnFilterState}
					onColumnFiltersChange={setColumnFilters}
					enableFilters={isSearchEnabled}
					data={isOutstandingTab ? outstandingChecks : clearedChecks}
					getRowId={(check) => check.recnum.toString()}
				/>
			</div>
		</div>
	);
};

CheckbookReconcileChecksTable.displayName = 'CheckbookReconcileChecksTable';

export { CheckbookReconcileChecksTable };
