import { apiClient } from 'api/apiClient';

const RESOURCE = '/PublicMappings';

export interface IPublicMappingParams {
	fileId: string;
	label: string;
}

export interface IPublicMappingsResponse {
	link: string | null;
}

export const postPublicMappings = async (
	params: IPublicMappingParams
): Promise<IPublicMappingsResponse> => {
	const { data } = await apiClient.post(RESOURCE, params);
	return data;
};
