import { useGet1099CSVFile } from 'api/hooks/useGet1099CsvFile';
import { useGet1099VendorDefaults } from 'api/hooks/useGet1099VendorDefaults';
import BaseDropdown from 'legacy/app/components/dropdowns/BaseDropdown';
import FormCurrencyInput from 'legacy/app/components/form/FormCurrencyInput';
import Label from 'legacy/app/components/label/Label';
import ConfirmationModal from 'legacy/app/components/modal/ConfirmationModal';
import {
	displayAlertError,
	displayAlertSuccess,
} from 'legacy/utilities/Response';
import { useEffect, useMemo } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import { Controller, useForm } from 'react-hook-form';
import { TTypeOption } from 'legacy/app/components/dropdowns/types/TTypeOption';

type T1099FormModalValues = {
	type: TTypeOption | null;
	year: string;
	minAmount: string;
};

export const E1099FileModal = ({
	toggleModal,
}: {
	toggleModal: () => void;
}) => {
	const { data, isLoading, error } = useGet1099VendorDefaults();
	const { isLoading: isGettingCsv, mutateAsync } = useGet1099CSVFile();
	const methods = useForm<T1099FormModalValues>({
		values: {
			type: null,
			minAmount: String(data?.minimunAmount),
			year: String(data?.year),
		},
	});

	const typeValue = methods.watch('type');
	const minAmountValue = methods.watch('minAmount');
	const yearValue = methods.watch('year');

	const yearInput = methods.register('year', {
		required: true,
		pattern: /^[0-9\b]+$/,
		minLength: 4,
	});

	const options = useMemo(
		() =>
			data?.types.map((typeOption) => ({
				label: typeOption,
				value: typeOption,
			})),
		[data]
	);

	const onSubmit = async () => {
		try {
			const csvData = await mutateAsync({
				type: typeValue?.value as string,
				year: Number(yearValue),
				minimumAmount: Number(minAmountValue),
			});

			const file = new File(
				[csvData],
				`${yearValue}-${typeValue?.value}-1099.csv`,
				{
					type: 'text/csv;charset=utf-8',
				}
			);

			saveAs(file);

			displayAlertSuccess('1099 CSV file downloaded successfully');

			toggleModal();
		} catch (error) {
			const responseError = error as {
				response?: { data?: { userError?: string } };
			};
			displayAlertError(
				responseError?.response?.data?.userError ||
					'There was an error getting the CSV file, please try again'
			);
		}
	};

	useEffect(() => {
		if (error) {
			displayAlertError(
				'There was an error getting the default values, please try again'
			);
		}
	}, [error]);

	return (
		<ConfirmationModal
			show
			onCancel={toggleModal}
			confirmAction={onSubmit}
			title="Create 1099 E-File"
			okBtnStyle="primary"
			labelCancel="Close"
			labelOK="Download"
			disableCancel={isGettingCsv}
			disabledOK={!methods.formState.isValid || isGettingCsv || !typeValue}
		>
			{isLoading ? (
				<div className="tw-flex tw-items-center tw-justify-center">
					<Spinner />
				</div>
			) : (
				data && (
					<div>
						<div className="tw-flex tw-gap-4 tw-items-center tw-pb-8">
							<Label label="1099 Type" />
							<div className="tw-flex-1">
								<Controller
									control={methods.control}
									name="type"
									render={({ field }) => (
										<BaseDropdown
											options={options}
											value={field.value}
											onChange={field.onChange}
										/>
									)}
								/>
							</div>
						</div>
						<div className="tw-flex tw-gap-4 tw-pb-8">
							<div className="tw-flex tw-gap-4 tw-items-center">
								<Label label="Year" />
								<Form.Control
									id="Year"
									maxLength={4}
									ref={yearInput.ref}
									name={yearInput.name}
									onChange={yearInput.onChange}
									onInput={(e) => {
										const isAValidNumber = /^[0-9\b]+$/;
										if (!isAValidNumber.test(e.currentTarget.value)) {
											const valueUntilTheLastChar =
												e.currentTarget.value.substring(
													0,
													e.currentTarget.value.lastIndexOf(
														e.currentTarget.value[
															e.currentTarget.value.length - 1
														]
													)
												);
											e.currentTarget.value = valueUntilTheLastChar;
										}
									}}
								/>
							</div>
							<div className="tw-flex tw-gap-4 tw-items-center">
								<Label label="Minimum" />
								<Controller
									control={methods.control}
									name="minAmount"
									render={({ field }) => (
										<div className="tw-col-span-2 tw-w-full">
											<FormCurrencyInput
												style={{
													height: '2.563rem',
												}}
												allowNegativeValue={false}
												value={field.value}
												onValueChange={field.onChange}
												onBlur={(e) => {
													if (!e.target.value) {
														field.onChange('0.00');
													}
												}}
											/>
										</div>
									)}
								/>
							</div>
						</div>
					</div>
				)
			)}
		</ConfirmationModal>
	);
};
E1099FileModal.displayName = 'E1099FileModal';
