import { useQuery } from '@tanstack/react-query';
import { getClientInvoices } from 'api/resources/clientInvoices';
import { ECacheKeys } from 'cache/CacheKeys';
import { displayAlertError } from 'legacy/utilities/Response';
import { useEffect } from 'react';

export const useGetClientInvoices = (filter?: string) => {
	const query = useQuery({
		queryKey: [ECacheKeys.ClientInvoices, filter],
		queryFn: () => getClientInvoices(filter),
		refetchOnWindowFocus: false,
		refetchInterval: false,
	});

	useEffect(() => {
		if (query.error) {
			displayAlertError(
				'There was an error getting the client invoices, please try again'
			);
		}
	}, [query.error]);

	return query;
};
