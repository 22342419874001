import React from 'react';
import AddDropdown from 'legacy/app/components/dropdowns/AddDropdown';
import SecureBootstrapButton from 'legacy/app/components/security/SecureBootstrapButton';
import { SECURITY_ATTRIBUTE_TYPES } from 'legacy/app/context/security';
import { InventoryTypes } from 'legacy/app/enums/inventoryTypes/inventoryTypes';
import { Component } from 'legacy/lib/api/types/Component';
import { useParams } from 'react-router-dom';
import { Item } from 'legacy/lib/api/types/Item';

const PurchaseComponentsButton = ({
	disableDelete,
	onDeleteClick,
	submitMethods,
	removeStockButton,
	disableAdd,
	components,
	setDelayReload,
	firstComponent,
	hasSalesCategory,
}: {
	removeStockButton: boolean;
	submitMethods: (
		customRedirectPath?: string,
		getEditedItem?: (item: Item) => void
	) => Promise<void>;
	components: Component[];
	disableDelete: boolean;
	disableAdd: boolean;
	onDeleteClick: () => void;
	setDelayReload: (value: boolean) => void;
	firstComponent?: Component;
	hasSalesCategory: boolean;
}) => {
	const { id: projectId, itemId } = useParams();
	return (
		<div className="tw-flex tw-gap-4">
			{components?.length > 0 && (
				<SecureBootstrapButton
					attributeNo={14}
					attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
					variant="ivory"
					size="sm"
					disabled={disableDelete}
					onClick={onDeleteClick}
				>
					<i className="ri-close-line" /> Delete
				</SecureBootstrapButton>
			)}
			{!removeStockButton && (
				<AddDropdown
					resetQueries
					locationQuery={`?$filter=(projectId eq null or projectId eq ${projectId})&$orderby=locn asc`}
					projectId={projectId as string}
					type={InventoryTypes.Component}
					itemId={itemId}
					submitMethods={submitMethods}
					setDelayReload={setDelayReload}
					firstComponent={firstComponent}
					hasSalesCategory={hasSalesCategory}
				/>
			)}
			<SecureBootstrapButton
				attributeNo={14}
				attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
				variant="primary"
				size="sm"
				onClick={() => submitMethods()}
				disabled={disableAdd}
			>
				<i className="ri-add-line" /> Add
			</SecureBootstrapButton>
		</div>
	);
};
PurchaseComponentsButton.displayName = 'PurchaseComponentsButton';
export default PurchaseComponentsButton;
