import cn from 'classnames';
import { Button } from 'react-bootstrap';
import { useCheckbookReconcile } from './hooks/useCheckbookReconcile';

const CheckbookReconcileTabs = () => {
	const { tab, setTab } = useCheckbookReconcile();

	return (
		<div className="tw-grid tw-grid-flow-col tw-gap-2 tw-py-2 tw-items-center tw-justify-start">
			<Button
				className={cn(
					'!tw-border-transparent !tw-text-[#211f21]',
					'hover:!tw-bg-[#211F211A]',
					'focus-visible:!tw-shadow-none',
					{
						'!tw-bg-[#211F211A]': tab === 'outstanding',
						'!tw-bg-transparent': tab !== 'outstanding',
					}
				)}
				onClick={() => setTab('outstanding')}
			>
				Outstanding
			</Button>
			<Button
				className={cn(
					'!tw-border-transparent !tw-text-[#211f21]',
					'hover:!tw-bg-[#211F211A]',
					'focus-visible:!tw-shadow-none',
					{
						'!tw-bg-[#211F211A]': tab === 'cleared',
						'!tw-bg-transparent': tab !== 'cleared',
					}
				)}
				onClick={() => setTab('cleared')}
			>
				Cleared
			</Button>
		</div>
	);
};

CheckbookReconcileTabs.displayName = 'CheckbookReconcileTabs';

export { CheckbookReconcileTabs };
