import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { ApiService } from '../HttpService';
import { Item } from '../types/Item';

const api = new ApiService();

export type TEditItemPayload = Partial<Item> & { id: number };

const useEditItem = (
	options?: UseMutationOptions<
		Item,
		TEditItemPayload,
		TEditItemPayload,
		unknown
	>
) => {
	return useMutation({
		mutationFn: (editedItem: TEditItemPayload) => {
			const newlyEditedItem = api.editProjectItem(
				editedItem.id,
				editedItem
			) as Promise<Item>;
			return newlyEditedItem;
		},
		...(options || {}),
	});
};
export default useEditItem;
