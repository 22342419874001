import React, { useEffect, useState } from 'react';
import { displayAlert } from 'legacy/utilities/Response';
import { EAlertTypes } from 'legacy/app/enums/alertTypes/alertTypes';
import usePaginatedQuery from 'legacy/lib/api/hooks/usePaginatedQuery';
import BaseSelect from 'legacy/app/components/Selects/BaseSelect';
import { TTypeLocation } from 'legacy/lib/api/types/Location';
import { getLocations } from 'api/resources/locations';

interface ILocationSelect {
	handleSelect: (location: TTypeLocation | null) => void;
	defaultSelected?: string;
	projectId: string;
	isClearable?: boolean;
}

export const LocationSelect = ({
	handleSelect,
	defaultSelected,
	projectId,
	isClearable = false,
}: ILocationSelect) => {
	const locationFilter = `(projectId eq ${projectId} or projectId eq null)`;
	const defaultFilter = `?$filter=${locationFilter}&$orderby=locn asc`;

	const [isDefaultSet, setIsDefaultSet] = useState(false);
	const [defaultLocation, setDefaultLocation] = useState<TTypeLocation | null>(
		null
	);

	const {
		data: locationItems,
		updateFilter,
		loadMore,
		hasMore,
		isFetching,
	} = usePaginatedQuery<TTypeLocation>(async (filter: string) => {
		return await getLocations(filter);
	}, defaultFilter);

	const optionFormatter = (location: TTypeLocation) => ({
		value: location.loc,
		label: `${location.locn} [${location.loc}]`,
		data: location,
	});

	const handleInputFilter = (input: string) => {
		return `?$filter=(contains(locn,'${input}') or contains(loc,'${input}')) and ${locationFilter}`;
	};

	const handleSelectedOption = (
		option: { value: string; data: TTypeLocation } | null
	) => {
		return option?.data || null;
	};

	useEffect(() => {
		const fetchDefaultLocation = async () => {
			try {
				if (defaultSelected && !isDefaultSet) {
					const filter = `?$filter=loc eq '${defaultSelected}' and ${locationFilter}&$orderby=locn asc`;
					const [fetchedDefaultLocation] = await getLocations(filter);

					if (fetchedDefaultLocation) {
						const formattedDefaultOption = optionFormatter(
							fetchedDefaultLocation
						);
						setDefaultLocation(fetchedDefaultLocation);
						handleSelect(formattedDefaultOption.data);
						setIsDefaultSet(true);
					}
				}
			} catch (error: unknown) {
				const responseError = error as {
					response?: { data?: { userError?: string } };
				};
				displayAlert(
					EAlertTypes.Danger,
					responseError.response?.data?.userError || 'Unknown error'
				);
			}
		};

		if (defaultSelected && !isDefaultSet) {
			fetchDefaultLocation();
		}
	}, [defaultSelected, handleSelect, isDefaultSet, locationFilter]);

	const removeDuplicates = (locations: TTypeLocation[] | undefined) => {
		if (!locations) return [];
		const uniqueMap = new Map<string, TTypeLocation>();
		locations.forEach((location) => {
			if (!uniqueMap.has(location.locn)) {
				uniqueMap.set(location.locn, location);
			}
		});
		return Array.from(uniqueMap.values());
	};

	return (
		<BaseSelect<TTypeLocation>
			handleSelect={handleSelect}
			placeholder="Select a Location"
			results={removeDuplicates(locationItems) || []}
			updateFilter={updateFilter}
			loadMore={loadMore}
			handleInputFilter={handleInputFilter}
			handleSelectedOption={handleSelectedOption}
			optionFormatter={optionFormatter}
			hasMore={hasMore}
			isFetching={isFetching}
			defaultSelected={defaultLocation}
			defaultFilter={defaultFilter}
			isClearable={isClearable}
		/>
	);
};

LocationSelect.displayName = 'LocationSelect';
