import { apiClient } from 'api/apiClient';

const EFILECSVPOST = '/Vendors/1099s/efileCsv';

const EFILECSVGET = '/files/';

export interface IFileCsvParams {
	type: string;
	year: number;
	minimumAmount: number;
}
export interface IFileCsvResponse {
	fileId: string;
}

export const get1099FileCSV = async (params: IFileCsvParams): Promise<Blob> => {
	const { data } = (await apiClient.post(`${EFILECSVPOST}`, params)) as {
		data: IFileCsvResponse;
	};
	const { data: file } = await apiClient.get(`${EFILECSVGET}${data.fileId}`, {
		responseType: 'blob',
	});

	return file;
};
