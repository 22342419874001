import React, { FC, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import HtmlToRtfBrowser from 'html-to-rtf-browser';
import {
	TForm as Model,
	Field,
	IFormField,
	TFormColumn,
} from '../components/Components';
import {
	fetchSalesCategories,
	fetchSuppliers,
	fetchUnitsOfMeasures,
} from '../../StockItemsService';
import { TTypeOption } from 'legacy/app/components/dropdowns/types/TTypeOption';
import classNames from 'classnames';

export const StockItemsEditItem: FC<{
	data: Map<string, any>;
	image?: File;
	onDataUpdate: (data: any) => void;
	onImageUpdate: (image: File) => void;
}> = ({ data, image, onDataUpdate, onImageUpdate }) => {
	const [unitsOfMeasures, setUnitsOfMeasures] = useState<TTypeOption[]>([]);
	const [salesCategories, setSalesCategories] = useState<TTypeOption[]>([]);
	const [suppliers, setSuppliers] = useState<TTypeOption[]>([]);

	const refs: any = {};
	const handleMarkup = (data: Map<string, any>, value: number) => {
		const unitCost = Number(data.get('unitestcost'));
		if (!unitCost || unitCost == 0) {
			return data;
		}

		const unitSellPrice = Number(unitCost + unitCost * value * 0.01).toFixed(2);
		data.set('unitprice', unitSellPrice);

		refs.unitprice.updateValue(unitSellPrice);

		return data;
	};

	const handleUnitSellPrice = (data: Map<string, any>, value: number) => {
		if (!value) {
			data.set('markup', 0);
			refs.markup.updateValue(0);
			return data;
		}

		const unitCost = Number(data.get('unitestcost'));
		if (!unitCost || unitCost == 0) {
			return data;
		}

		const markup = Number(((value - unitCost) / unitCost) * 100).toFixed(2);
		data.set('markup', markup);

		refs.markup.updateValue(markup);

		return data;
	};

	const handleUnitCost = (data: Map<string, any>, value: number) => {
		const unitCost = Number(value ?? 0);
		if (unitCost == 0) {
			data.set('unitprice', 0);
			refs.unitprice.updateValue(0);
			return data;
		}

		const existingMarkup = Number(data.get('markup') ?? 0);
		const unitSellPrice = Number(
			unitCost + unitCost * existingMarkup * 0.01
		).toFixed(2);
		data.set('unitprice', unitSellPrice);
		refs.unitprice.updateValue(unitSellPrice);

		return data;
	};

	const model: Model = {
		sections: [
			{
				key: 's1',
				columns: [
					{
						key: 'c0',
						fields: [
							{
								type: 'text',
								title: 'Name',
								ids: ['itemName'],
								value: data.get('itemName'),
								maxLength: 45,
							},
							{
								type: 'wysiwyg',
								title: 'Description',
								ids: ['desc'],
								value: data.get('descrtf'),
							},
							{
								type: 'text',
								title: 'Style/Size',
								ids: ['category1'],
								isTwoColumn: true,
								value: data.get('category1'),
								titleClass: '!tw-w-auto',
								maxLength: 20,
							},
							{
								type: 'text',
								title: 'Color/Finish',
								isTwoColumn: true,
								titleClass: '!tw-w-auto !tw-mr-4',
								ids: ['category2'],
								value: data.get('category2'),
								maxLength: 20,
							},
							{
								type: 'select',
								subtype: 'salesCategory',
								title: 'Sales Category',
								ids: ['scat'],
								value: data.get('scat'),
								options: salesCategories,
							},
							{
								type: 'currency',
								title: 'Est. Unit Cost',
								titleClass: '!tw-w-auto',
								isTwoColumn: true,
								ids: ['unitestcost'],
								value: data.get('unitestcost'),
								handler: handleUnitCost,
							},
							{
								type: 'select',
								subtype: 'custom',
								title: 'Unit of Measure',
								titleClass: '!tw-w-auto',
								isTwoColumn: true,
								ids: ['unitofmeasure'],
								options: unitsOfMeasures,
								value: data.get('unitofmeasure'),
							},
							{
								type: 'text',
								title: 'Markup %',
								ids: ['markup'],
								isTwoColumn: true,
								titleClass: '!tw-w-auto',
								inputRegex: /^\d*\.?\d{0,2}$/,
								value: data.get('markup'),
								handler: handleMarkup,
							},
							{
								type: 'currency',
								title: 'Unit Sell Price',
								isTwoColumn: true,
								ids: ['unitprice'],
								titleClass: '!tw-w-auto',
								value: data.get('unitprice'),
								handler: handleUnitSellPrice,
							},
							/* 						{
								type: 'text',
								title: 'Reorder Point',
								ids: ['reorderpt'],
								value: data.get('reorderpt'),
							},
							{
								type: 'text',
								title: 'Reorder Qty.',
								ids: ['reorderqty'],
								value: data.get('reorderqty'),
							},
							{
								type: 'text',
								title: 'PO Sidemark',
								ids: ['sidemark'],
								value: data.get('sidemark'),
							}, */
						],
						sm: 12,
						lg: 6,
					},
					{
						key: 'c1',
						fields: [
							{
								type: 'text',
								title: 'Stock Number',
								ids: ['stockno'],
								value: data.get('stockno'),
								readOnly: true,
							},
							{
								type: 'dropzone',
								ids: ['primaryImageId'],
								value: data.get('primaryImageId'),
								file: image,
							},
							{
								type: 'text',
								title: 'Storage Location',
								ids: ['locbin'],
								value: data.get('locbin'),
								maxLength: 10,
							},
							{
								type: 'select',
								subtype: 'supplier',
								title: 'Vendor',
								isTwoColumn: true,
								ids: ['supplier'],
								value: data.get('supplier'),
								options: suppliers,
							},
							{
								type: 'text',
								title: 'MFG Cat. No.',
								ids: ['mfgcat'],
								isTwoColumn: true,
								value: data.get('mfgcat'),
								maxLength: 30,
							},
							/* 						{
								type: 'text',
								title: 'Bar Code',
								ids: ['barcode'],
								readOnly: true,
								value: data.get('barcode'),
							}, */
							{
								type: 'checkbox',
								title: 'Inactive',
								ids: ['inactive'],
								value: data.get('inactive'),
							},
						],
						sm: 12,
						lg: 6,
					},
				],
			},
		],
	};

	useEffect(() => {
		const fetchData = async () => {
			setUnitsOfMeasures(await fetchUnitsOfMeasures());
			setSalesCategories(await fetchSalesCategories());
			setSuppliers(await fetchSuppliers());
		};
		fetchData();
	}, []);

	const htmlToRtfConverter = new HtmlToRtfBrowser();

	const handleChange = (field: IFormField, value: any) => {
		const newData = field.handler?.(new Map(data), value) ?? new Map(data);

		switch (field.type) {
			case 'text':
			case 'currency':
			case 'select':
			case 'checkbox':
				field.ids.forEach((id) => {
					newData.set(id, value);
				});
				break;
			case 'wysiwyg':
				field.ids.forEach((id) => {
					newData.set(id, value.value);
					newData.set(
						`${id}rtf`,
						htmlToRtfConverter
							.convertHtmlToRtf(value.html)
							.replaceAll('\\~', ' ')
					);
				});
				break;
			case 'dropzone':
				field.ids.forEach(() => {
					onImageUpdate(value);
				});
				return;
		}

		onDataUpdate(newData);
	};

	const getItemsToRender = (column: TFormColumn) => {
		const alreadyCheckedIndexes: number[] = [];
		const renderedItems = column.fields.reduce((acc, currentItem, index) => {
			if (alreadyCheckedIndexes.includes(index)) {
				return acc;
			}
			if (currentItem.isTwoColumn) {
				// If the item has the twoColumns property, include the current item and the next one
				if (column.fields[index + 1]?.isTwoColumn) {
					acc.push(
						<div
							key={currentItem.ids[0]}
							className="tw-flex tw-gap-8 tw-mb-4 tw-items-center !tw-pr-2"
						>
							<div className="tw-min-w-52">
								<Field
									labelClass="!tw-pr-2"
									ref={(ref) => {
										refs[`${currentItem.ids[0]}`] = ref;
									}}
									colClass="!tw-w-auto !tw-pr-2 !tw-items-center tw-flex"
									model={currentItem}
									onChange={(value) => {
										handleChange(currentItem, value);
									}}
								/>
							</div>
							<div className="tw-ml-auto">
								<Field
									labelClass="!tw-pr-2"
									colClass="!tw-w-auto !tw-pr-2 !tw-items-center tw-flex"
									selectClassName="tw-min-w-72"
									ref={(ref) => {
										refs[`${column.fields[index + 1].ids[0]}`] = ref;
									}}
									model={column.fields[index + 1]}
									onChange={(value) => {
										handleChange(column.fields[index + 1], value);
									}}
								/>
							</div>
						</div>
					);
					alreadyCheckedIndexes.push(index);
					alreadyCheckedIndexes.push(index + 1);
					return acc;
				}
			} else {
				// If it doesn't have twoColumns, just include the current item
				acc.push(
					<div key={currentItem.ids[0]} className="tw-mb-4 tw-pr-2">
						<Field
							selectClassName="tw-min-w-52"
							labelClass={classNames('!tw-pr-2')}
							colClass={classNames(
								'!tw-w-auto  tw-flex',
								currentItem.type === 'wysiwyg' && '!tw-pr-2',
								currentItem.type !== 'wysiwyg' && '!tw-items-center'
							)}
							ref={(ref) => {
								refs[`${currentItem.ids[0]}`] = ref;
							}}
							model={currentItem}
							onChange={(value) => {
								handleChange(currentItem, value);
							}}
						/>
					</div>
				);
			}
			return acc;
		}, [] as JSX.Element[]);
		return renderedItems;
	};

	return (
		<Container className="ms-0">
			<div>
				{model.sections.map((section) => {
					return (
						<div
							key={section.key}
							className="tw-flex tw-flex-wrap tw-justify-between tw-gap-24"
						>
							{section.columns.map((column) => {
								return (
									<div className="tw-flex-1" key={column.key}>
										{getItemsToRender(column)}
									</div>
								);
							})}
						</div>
					);
				})}
			</div>
			<div className="!tw-p-4 roundedx-6 bg-ivory border-1 border-sand tw-flex tw-items-center">
				<p className="tw-font-bold tw-mb-0 tw-text-base">Notes</p>
			</div>
			<div className="tw-mt-4">
				<Field
					ref={(ref) => {
						refs['notes'] = ref;
					}}
					hideTextEditorLabel
					model={{
						type: 'wysiwyg',
						title: 'Notes',
						ids: ['notes'],
						value: data.get('notesrtf'),
					}}
					onChange={(value) => {
						handleChange(
							{
								type: 'wysiwyg',
								title: 'Notes',
								ids: ['notes'],
								value: data.get('notesrtf'),
							},
							value
						);
					}}
				/>
			</div>
		</Container>
	);
};
StockItemsEditItem.displayName = 'StockItemsEditItem';
