import { apiClient } from 'api/apiClient';
import type { Component } from 'legacy/lib/api/types/Component';

const RESOURCE = '/Components';

export const patchComponent = async (
	params: Partial<Component>
): Promise<Component> => {
	const { data } = await apiClient.patch(`${RESOURCE}/${params?.id}`, params);
	return data;
};
