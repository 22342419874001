export type UserError = {
	isOk: boolean;
	resourceNotAuhtorized: boolean;
	resourceNotFound: boolean;
	resourceInvalidPayload: boolean;
	resourceForbidden: boolean;
	userError: `${INVALID_REQUESTS}`;
	internalError: string;
	errorCode: string;
};
export enum INVALID_REQUESTS {
	ITEM_IS_FROM_INVENTORY = 'You cannot save changes to an Item that has components from Inventory',
	INVALID_INPUT = 'Please make sure your inputs have correct values.',
}
