import { apiClient } from 'api/apiClient';

const RESOURCE = '/TemporaryReconciliations';

export interface ITemporaryReconciliationsStartParams {
	cashAccount: string;
	statementDate: string;
}

export interface ITemporaryReconciliationsAcceptParams {
	cashAccount: string | null;
	statementEndingDate: string | null;
	statementBalance: number;
	balance: number;
	computerBalance: number;
}

export interface ITemporaryReconciliationsTotalsParams {
	account: string | null;
	doClearFlagsChecks: boolean;
	doSumFlagsChecks: boolean;
	doClearFlagsDeps: boolean;
	doSumFlagsDeps: boolean;
	statementEndingDate: string | null;
	statementBalance: number;
}

export interface ITemporaryReconciliationsParams {
	usercode: number;
	recnum: number;
	cleared?: boolean | null;
	sumtag?: boolean | null;
}

export interface ITemporaryReconciliationCheck {
	usercode: number;
	recnum: number;
	amount: number | null;
	cleared: boolean | null;
	checknum: string | null;
	userdate: string | null;
	supplier: string | null;
	depositslip: string | null;
	type: number | null;
	txnum: number | null;
	entrytype: number | null;
	gridnum: number | null;
	txdesc: string | null;
	servicechg: boolean | null;
	bfmatch: boolean | null;
	ssmaTimeStamp: string | null;
	sumtag: boolean | null;
}

export interface ITemporaryReconciliationDeposit {
	usercode: number;
	recnum: number;
	amount: number | null;
	cleared: boolean | null;
	checknum: string | null;
	userdate: string | null;
	supplier: string | null;
	depositslip: string | null;
	type: number | null;
	txnum: number | null;
	entrytype: number | null;
	gridnum: number | null;
	txdesc: string | null;
	servicechg: boolean | null;
	bfmatch: boolean | null;
	ssmaTimeStamp: string | null;
	sumtag: boolean | null;
}

export interface ITemporaryReconciliationTotals {
	checksFinanceCharge: number;
	totalClearedChecks: number;
	checkClearedCount: number;
	totalClearedDeposits: number;
	depositsClearedCount: number;
	checkSumTotal: number;
	checkSumCount: number;
	depositsSumTotal: number;
	depositsSumCount: number;
	depositsInTransit: number;
	outstandingChecks: number;
	balance: number;
	computerBalance: number;
	difference: number;
}

export interface ITemporaryReconciliations {
	usercode: number;
	recnum: number;
	amount: number | null;
	cleared: boolean | null;
	checknum: string | null;
	userdate: string | null;
	supplier: string | null;
	depositslip: string | null;
	type: number | null;
	txnum: number | null;
	entrytype: number | null;
	gridnum: number | null;
	txdesc: string | null;
	servicechg: boolean | null;
	bfmatch: boolean | null;
	ssmaTimeStamp: string | null;
	sumtag: boolean | null;
}

export const postTemporaryReconciliationsStart = async (
	params: ITemporaryReconciliationsStartParams
) => {
	const { data } = await apiClient.post(`${RESOURCE}/Start`, params);
	return data;
};

export const postTemporaryReconciliationsAccept = async (
	params: ITemporaryReconciliationsAcceptParams
) => {
	const { data } = await apiClient.post(`${RESOURCE}/Accept`, params);
	return data;
};

export const getTemporaryReconciliationsChecks = async (
	filter: string
): Promise<ITemporaryReconciliationCheck[]> => {
	const { data } = await apiClient.get(`${RESOURCE}/Checks${filter}`);
	return data;
};

export const getTemporaryReconciliationsDeposits = async (
	filter: string
): Promise<ITemporaryReconciliationDeposit[]> => {
	const { data } = await apiClient.get(`${RESOURCE}/Deposits${filter}`);
	return data;
};

export const postTemporaryReconciliationsTotals = async (
	params: ITemporaryReconciliationsTotalsParams
): Promise<ITemporaryReconciliationTotals> => {
	const { data } = await apiClient.post(`${RESOURCE}/Totals`, params);
	return data;
};

export const patchTemporaryReconciliations = async (
	params: ITemporaryReconciliationsParams
): Promise<ITemporaryReconciliations> => {
	const { data } = await apiClient.patch(RESOURCE, params);
	return data;
};
