import { useQuery } from '@tanstack/react-query';
import { ApiService } from '../HttpService';
import { IItemImages } from '../types/ItemImages';
import { useEffect } from 'react';
import { displayAlertError } from 'legacy/utilities/Response';

const api = new ApiService();

const getFile = async (id: string) => {
	return (await api.getFile(id)) as Blob;
};

const useGetItemImages = (filter = '') => {
	const imagesQuery = useQuery<IItemImages[], Error>(
		['itemImages', filter],
		async () => {
			const itemImages = await api.getItemImages(filter);

			const itemImagesWithFiles = await Promise.all(
				itemImages?.map(async (image) => {
					const imageFile = await getFile(image.fileId);
					return {
						...image,
						imageFile,
					};
				})
			);
			return itemImagesWithFiles;
		},
		{
			refetchOnWindowFocus: false,
			refetchOnReconnect: false,
			staleTime: 0,
			cacheTime: 0,
		}
	);

	useEffect(() => {
		if (imagesQuery.error) {
			displayAlertError(
				'There was a problem getting the images, please try again'
			);
		}
	}, [imagesQuery.error]);

	return {
		isLoading: imagesQuery.isLoading,
		data: imagesQuery.data,
		error: imagesQuery.error,
	};
};

export default useGetItemImages;
