import React from 'react';
import { Row, Col, Modal, Form, Button } from 'react-bootstrap';
import { WithRouter } from '../../../helpers/Router';
import ThreadComponent from '../ThreadComponent';
import { ApiService } from '../../../lib/api/HttpService';
import { displayAlert, displayAlertError } from '../../../utilities/Response';
import { isEmpty } from 'lodash';
import { isEmail } from 'legacy/utilities/Validation';

class RequestRetainerModal extends ThreadComponent {
	constructor(props) {
		super(props);

		this.state = {
			amount: 0,
			hasFile: false,
			to: '',
			cc: '',
			bcc: '',
			subject: '',
			message: '',
			company: {},
			includeSignature: false,
		};
		this.api = new ApiService();
	}

	async componentDidMount() {
		const company = await this.api.get('company');
		await this.getAddressDetails(company);
	}

	async getAddressDetails(company) {
		const address = await this.api.get(
			'addresses',
			`?$filter=code eq '${this.props.projectCode}' and addresstype eq 0`
		);
		this.setState({
			to: !isEmpty(address) ? address[0].email : '',
			cc: !isEmpty(address) ? address[0].emailcc : '',
			bcc: !isEmpty(address) ? address[0].emailbcc : '',
			subject: `Retainer Request from ${company.name}`,
			message: `${company.name} would like to inform you that a new retainer has been requested for your project.  To view and pay the retainer, click the button below.`,
		});
	}

	onFileChange = (e) => {
		this.setState((prev) => (prev.hasFile = e.target.value ? true : false));
	};

	handleChange = (e) => {
		this.setState((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));
	};

	onSend = async () => {
		const postData = {
			requestedRetainerAmount: this.state.amount,
			proj: this.props.projectCode,
		};

		let hasError = false;
		await this.api
			.editProject(this.props.params.id, postData)
			.catch((error) => {
				hasError = true;
				displayAlert('danger', error.response.data.userError);
			});

		let token = null;
		await this.api
			.createPublicMapping({
				objectId: this.props.params.id,
			})
			.then((response) => {
				if (response.isOk) {
					token = response.token;
				}
			})
			.catch((error) => {
				hasError = true;
				displayAlert('danger', error.response.data.userError);
			});

		if (!this.state.to) {
			displayAlert('danger', 'Field "To" cannot be empty');
			return;
		}

		const normalisedTo = this.state.to.split(';').map((em) => em.trim());
		const normalisedCC = this.state.cc
			? this.state.cc.split(';').map((em) => em.trim())
			: [];
		const normalisedBcc = this.state.bcc
			? this.state.bcc.split(';').map((em) => em.trim())
			: [];

		const invalidTo = normalisedTo.find((em) => isEmail(em) === false);
		const invalidCc = normalisedCC.find((em) => isEmail(em) === false);
		const invalidBcc = normalisedBcc.find((em) => isEmail(em) === false);

		if (invalidTo !== undefined) {
			return displayAlertError(
				"There were invalid email addresses in the 'To' field. Please correct them and try again."
			);
		}

		if (invalidCc !== undefined) {
			return displayAlertError(
				"There were invalid email addresses in the 'CC' field. Please correct them and try again."
			);
		}

		if (invalidBcc !== undefined) {
			return displayAlertError(
				"There were invalid email addresses in the 'BCC' field. Please correct them and try again."
			);
		}

		let notificationData = {
			notificationTypeId: 1,
			emailData: {
				to: normalisedTo,
				subject: this.state.subject,
				message: this.state.message,
			},
			publicMappingBatchToken: token,
			includeUserSignature: this.state.includeSignature,
		};

		if (this.state.cc) {
			notificationData.emailData.cc = normalisedCC;
		}

		if (this.state.bcc) {
			notificationData.emailData.bcc = normalisedBcc;
		}

		await this.api.postNotification(notificationData).catch((error) => {
			hasError = true;
			displayAlert('danger', error.response.data.userError);
		});

		if (!hasError) {
			displayAlert('success', 'Request retainer email sent');
			setTimeout(() => {
				this.props.hideModal();
			}, 500);
		}
	};

	render() {
		return (
			<Modal
				size="lg"
				show={this.props.show}
				onHide={() => this.props.hideModal()}
				aria-labelledby="example-modal-sizes-title-lg"
				className="right a-modal fixed-footer"
				backdrop="static"
			>
				<Modal.Body>
					<div className="p-5 mt-5 mx-auto" style={{ maxWidth: '600px' }}>
						<h4 className="ff-type-medium">Request Retainer Email</h4>
						<Row className="form-fields px-4 mt-5">
							<Form.Group
								as={Row}
								className="mb-3"
								controlId="formPlaintextEmail"
							>
								<Form.Label
									column
									sm="2"
									className="fw-700 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
								>
									Amount to
									<br />
									Request:
								</Form.Label>
								<Col sm="10">
									<div className="form-group-extra">
										<Form.Control
											name="amount"
											type="text"
											placeholder="0.00"
											size="sm"
											onChange={this.handleChange}
										/>
										<span className="text-secondary-ash">USD</span>
									</div>
								</Col>
							</Form.Group>
							<Form.Group
								as={Row}
								className="mb-3"
								controlId="formPlaintextEmail"
							>
								<Form.Label
									column
									sm="2"
									className="fw-700 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
								>
									To:
								</Form.Label>
								<Col sm="10">
									<Form.Control
										name="to"
										type="text"
										placeholder=""
										size="sm"
										onChange={this.handleChange}
										defaultValue={this.state.to}
									/>
								</Col>
							</Form.Group>
							<Form.Group
								as={Row}
								className="mb-3"
								controlId="formPlaintextEmail"
							>
								<Form.Label
									column
									sm="2"
									className="fw-700 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
								>
									Cc:
								</Form.Label>
								<Col sm="10">
									<Form.Control
										name="cc"
										type="text"
										placeholder=""
										size="sm"
										onChange={this.handleChange}
										defaultValue={this.state.cc}
									/>
								</Col>
							</Form.Group>
							<Form.Group
								as={Row}
								className="mb-4 pb-2"
								controlId="formPlaintextEmail"
							>
								<Form.Label
									column
									sm="2"
									className="fw-700 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
								>
									Bcc:
								</Form.Label>
								<Col sm="10">
									<Form.Control
										name="bcc"
										type="text"
										placeholder=""
										size="sm"
										onChange={this.handleChange}
										defaultValue={this.state.bcc}
									/>
								</Col>
							</Form.Group>
							<Form.Group
								as={Row}
								className="mb-3"
								controlId="formPlaintextEmail"
							>
								<Form.Label
									column
									sm="2"
									className="fw-700 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
								>
									Subject:
								</Form.Label>
								<Col sm="10">
									<Form.Control
										name="subject"
										type="text"
										placeholder=""
										size="sm"
										onChange={this.handleChange}
										defaultValue={this.state.subject}
									/>
								</Col>
							</Form.Group>
							<Form.Group
								as={Row}
								className="mb-3"
								controlId="formPlaintextEmail"
							>
								<Form.Label
									column
									sm="2"
									className="fw-700 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
								>
									Attachment:
								</Form.Label>
								<Col sm="10">
									<div className="form-group-extra">
										<Form.Control
											type="file"
											placeholder=""
											title=""
											size="sm"
											className={`file-upload-white ${
												this.state.hasFile ? 'has-file' : ''
											}`}
											onChange={this.onFileChange}
										/>
										<span className="text-secondary-ash ri-attachment-line"></span>
									</div>
								</Col>
							</Form.Group>
							<Form.Group
								as={Row}
								className="mb-3"
								controlId="formPlaintextEmail"
							>
								<Form.Label
									column
									sm="2"
									className="fw-700 d-flex justify-content-sm-end align-items-start px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
								>
									Message:
								</Form.Label>
								<Col sm="10">
									<Form.Control
										name="message"
										as="textarea"
										rows={10}
										placeholder=""
										size="sm"
										onChange={this.handleChange}
										defaultValue={this.state.message}
									/>
								</Col>
							</Form.Group>
							<Form.Group
								as={Row}
								className="mb-3"
								controlId="formPlaintextEmail"
							>
								<Form.Label
									column
									sm="2"
									className="fw-700 d-flex justify-content-sm-end align-items-center px-sm-0 py-0 mb-1 mb-sm-0 text-sm-end"
								></Form.Label>
								<Col sm="10">
									<Form.Check
										label="Include Signature from User Settings"
										name="includeSignature"
										type="checkbox"
										id={`inline-md-radio-4`}
										onChange={() => {
											this.setState({
												includeSignature: !this.state.includeSignature,
											});
										}}
									/>
								</Col>
							</Form.Group>
						</Row>
					</div>
				</Modal.Body>
				<Modal.Footer className="bg-ivory py-4 px-5">
					<Row className="w-100">
						<Col lg={{ span: 10, offset: 1 }}>
							<Button
								variant="primary"
								onClick={this.onSend}
								className="w-100 mb-2"
							>
								Send
							</Button>
							<Button
								variant="trans-light border-secondary-ash"
								onClick={this.props.hideModal.bind(null)}
								className="w-100"
							>
								Cancel
							</Button>
						</Col>
					</Row>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default WithRouter(RequestRetainerModal);
