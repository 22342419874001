import { apiClient } from 'api/apiClient';

const RESOURCE = '/Vendors/1099s/defaults';

export interface IVendorDefaults {
	types: string[];
	year: number;
	minimunAmount: number;
}

export const get1099VendorDefaults = async (): Promise<IVendorDefaults> => {
	const { data } = await apiClient.get(`${RESOURCE}`);

	return data;
};
