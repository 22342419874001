import React, { Fragment, useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import ConfirmationModal from 'legacy/app/components/modal/ConfirmationModal';
import InventoryItemForm from '../forms/InventoryItemForm/InventoryItemForm';
import { InventoryTypes } from '../../enums/inventoryTypes/inventoryTypes';
import { displayAlert } from '../../../utilities/Response';
import { EAlertTypes } from '../../enums/alertTypes/alertTypes';
import { useQueryClient } from '@tanstack/react-query';
import usePageReload from 'legacy/lib/api/hooks/usePageReload';
import { Component } from 'legacy/lib/api/types/Component';
import useGetCompany from 'legacy/lib/api/hooks/useGetCompany';

interface IAddDropdownProps {
	locationQuery: string;
	projectId: string;
	type: InventoryTypes;
	itemId?: string;
	resetQueries?: boolean;
	submitMethods?: () => Promise<void>;
	setDelayReload?: (value: boolean) => void;
	firstComponent?: Component;
	hasSalesCategory?: boolean;
}

interface IResponseMessage {
	message?: string;
	type?: EAlertTypes;
}

const modalItemTitle = 'Add Item from Inventory';
const modalComponentTitle = 'Add new Component from Inventory';

const AddDropdown = ({
	locationQuery,
	projectId,
	type,
	resetQueries,
	itemId,
	submitMethods,
	setDelayReload,
	firstComponent,
	hasSalesCategory,
}: IAddDropdownProps) => {
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const { data } = useGetCompany();
	const salesCategoryIsRequired =
		data?.requirescat && type === InventoryTypes.Component;
	const queryClient = useQueryClient();
	const reloadPage = usePageReload();
	const title =
		type === InventoryTypes.Item ? modalItemTitle : modalComponentTitle;

	const handleCloseModal = async (response?: IResponseMessage) => {
		setShowConfirmationModal(false);
		if (response?.message) {
			displayAlert(response?.type, response?.message, 7000);
			if (response?.type === EAlertTypes.Success) {
				resetQueries
					? await queryClient.resetQueries([itemId, projectId, 'components'])
					: reloadPage();
			}
		}
	};

	useEffect(() => {
		if (type === InventoryTypes.Component && setDelayReload) {
			setDelayReload(showConfirmationModal);
		}
	}, [showConfirmationModal, setDelayReload, type]);

	const handleClick = () => {
		if (!hasSalesCategory) {
			displayAlert(EAlertTypes.Danger, 'Please enter a Sales Category');
		} else {
			return;
		}
	};

	return (
		<Fragment>
			<Dropdown
				className="d-inline"
				onToggle={(isOpen) => {
					if (isOpen) {
						salesCategoryIsRequired && handleClick();
					}
				}}
			>
				<Dropdown.Toggle id="dropdown-autoclose-true" variant="light" size="sm">
					Add From
				</Dropdown.Toggle>
				<Dropdown.Menu>
					<Dropdown.Item
						onClick={() => setShowConfirmationModal(true)}
						disabled={salesCategoryIsRequired && !hasSalesCategory}
					>
						Inventory
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
			<ConfirmationModal
				show={showConfirmationModal}
				title={title}
				okBtnStyle="primary"
				footer={false}
				size="lg"
			>
				<InventoryItemForm
					closeModal={handleCloseModal}
					locationQuery={locationQuery}
					projectId={projectId}
					type={type}
					itemId={itemId}
					submitMethods={submitMethods}
					firstComponent={firstComponent}
				/>
			</ConfirmationModal>
		</Fragment>
	);
};

AddDropdown.displayName = 'AddDropdown';

export default AddDropdown;
