import { getRunReports } from 'api/resources/runReports';
import { useQuery } from '@tanstack/react-query';
import type { IGetRunReportResponse } from 'api/resources/runReports';
import { ECacheKeys } from 'cache/CacheKeys';
import type { UseQueryOptions } from '@tanstack/react-query';

export const useGetRunReports = (
	reportId: string,
	options?: UseQueryOptions<IGetRunReportResponse[]>
) => {
	const query = useQuery<IGetRunReportResponse[]>({
		queryKey: [ECacheKeys.RunReports, reportId],
		queryFn: () => getRunReports(reportId),
		refetchInterval: false,
		refetchOnWindowFocus: false,
		...(options || {}),
	});

	return query;
};
