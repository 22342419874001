import {
	postPublicMappings,
	type IPublicMappingParams,
} from 'api/resources/publicMappings';
import { useMutation } from '@tanstack/react-query';

export const usePostPublicMappings = () => {
	return useMutation((params: IPublicMappingParams) =>
		postPublicMappings(params)
	);
};
