import React, { useEffect, useMemo } from 'react';
import Label from 'legacy/app/components/label/Label';
import { Form } from 'react-bootstrap';
import SecureContent from 'legacy/app/components/security/SecureContent';
import { SECURITY_ATTRIBUTE_TYPES } from 'legacy/app/context/security';
import { Controller, useFormContext } from 'react-hook-form';
import { ItemFormValues } from '../types/FormValues';
import { useGetStatusCodes } from 'api/hooks/useGetStatusCodes';
import { Item } from 'legacy/lib/api/types/Item';
import Spinner from 'legacy/app/components/help/Spinner';
import { displayAlertError } from 'legacy/utilities/Response';
import ProposalTable from './ProposalTable';
import InvoicesTable from './InvoicesTable';
import ItemTotals from './ItemTotals';

const StatusTab = ({
	itemToEdit,
	disableInputs,
}: {
	itemToEdit: Item;
	disableInputs: boolean;
}) => {
	const {
		isLoading,
		data: statusCodes,
		error: statusCodeFetchError,
	} = useGetStatusCodes();

	const statusCode = useMemo(
		() =>
			statusCodes?.find(
				(statusCode) => statusCode.statusNumber === itemToEdit.statusnumber
			)?.statusName,
		[statusCodes, itemToEdit]
	);

	const methods = useFormContext<ItemFormValues>();

	useEffect(() => {
		if (statusCodeFetchError) {
			displayAlertError(
				'There was an error getting the status code information, please try again'
			);
		}
	}, [statusCodeFetchError]);

	if (isLoading) {
		return <Spinner isChild />;
	}

	return !statusCodeFetchError ? (
		<div className="tw-min-h-[calc(100vh-360px)] tw-pt-10 tw-pb-10">
			<div className="tw-flex tw-items-center tw-justify-center tw-gap-6 tw-pb-4">
				<SecureContent
					attributeNo={14}
					attributeType={SECURITY_ATTRIBUTE_TYPES.DenySpec3}
				>
					<SecureContent.HasAccess>
						<div className="tw-flex tw-gap-6 tw-items-center">
							<Label label="Status" className="tw-font-bold" />
							<Form.Control
								value={statusCode}
								className="tw-flex-1"
								readOnly
								disabled
							/>
						</div>
						<div className="tw-flex tw-gap-4 tw-items-center">
							<Controller
								control={methods.control}
								name="inactive"
								render={({ field: { onChange, value } }) => (
									<Form.Check
										inline
										label="Inactive"
										disabled={disableInputs}
										type="checkbox"
										onChange={onChange}
										checked={value}
									/>
								)}
							/>
							<Controller
								control={methods.control}
								name="completed"
								render={({ field: { onChange, value } }) => (
									<Form.Check
										inline
										disabled={disableInputs}
										label="Completed"
										type="checkbox"
										onChange={onChange}
										checked={value}
									/>
								)}
							/>
							<Controller
								control={methods.control}
								name="bypasswip"
								render={({ field: { onChange, value } }) => (
									<Form.Check
										inline
										label="Bypass WIP"
										disabled={disableInputs}
										type="checkbox"
										onChange={onChange}
										checked={value}
									/>
								)}
							/>
						</div>
					</SecureContent.HasAccess>
				</SecureContent>
			</div>
			<ProposalTable itemToEdit={itemToEdit} />
			<InvoicesTable itemToEdit={itemToEdit} />
			<ItemTotals itemToEdit={itemToEdit} />
		</div>
	) : null;
};

StatusTab.displayName = 'StatusTab';

export default StatusTab;
