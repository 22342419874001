import { apiClient } from 'api/apiClient';

const RESOURCE = '/RunReports';

type TReportObject =
	| 'Report'
	| 'DeliveryTicket'
	| 'Invoice'
	| 'ShowroomOrder'
	| 'Proposal'
	| 'ShowroomQuote'
	| 'POSInvoice'
	| 'CreditCardReceipt'
	| 'PurchaseOrder'
	| 'Label'
	| 'WorkOrder'
	| 'FaxPO'
	| 'FaxWO'
	| 'InventoryPO'
	| 'InventoryFax'
	| 'DepositSlips'
	| 'Checks';

type TReportStatus = 'Pending' | 'Complete' | 'Error';

interface IRunReportParameter {
	parameterId: number;
	value?: string | null;
	label?: string | null;
	order?: number;
	typeId?: number;
}

export interface IGetRunReportResponse {
	status: TReportStatus;
	completedFileId: string | null;
}

export interface IPostRunReportsParams {
	instanceId: string;
	userId: number;
	reportId: number;
	reportFormatId: number;
	reportObjectType: TReportObject;
	objectId?: string | null;
	skipIfInProgress?: boolean;
	runReportParameters?: (IRunReportParameter | null)[] | null;
}

export interface IPostRunReportResponse {
	id: string | null;
}

export const postRunReports = async (
	params: IPostRunReportsParams
): Promise<IPostRunReportResponse> => {
	const { data } = await apiClient.post(RESOURCE, params);
	return data;
};

export const getRunReports = async (
	reportId: string
): Promise<IGetRunReportResponse[]> => {
	const { data } = await apiClient.get(RESOURCE + `?$filter=id eq ${reportId}`);
	return data;
};
