import { Form } from 'react-bootstrap';
import cn from 'classnames';

interface ICheckbookReconcileCheckboxHeaderProps {
	checked: boolean;
	handleOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	disabled: boolean;
}

const CheckbookReconcileCheckboxHeader = ({
	checked,
	handleOnChange,
	disabled,
}: ICheckbookReconcileCheckboxHeaderProps) => {
	return (
		<div>
			<Form.Check
				label=""
				type="checkbox"
				checked={checked}
				onChange={handleOnChange}
				className={cn({ line: checked })}
				disabled={disabled}
			/>
		</div>
	);
};
CheckbookReconcileCheckboxHeader.displayName =
	'CheckbookReconcileCheckboxHeader';

export { CheckbookReconcileCheckboxHeader };
