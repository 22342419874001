import { useMutation } from '@tanstack/react-query';
import {
	postTemporaryReconciliationsAccept,
	type ITemporaryReconciliationsAcceptParams,
} from 'api/resources/temporaryReconciliations';

export const usePostTemporaryReconciliationsAccept = () => {
	return useMutation((params: ITemporaryReconciliationsAcceptParams) =>
		postTemporaryReconciliationsAccept(params)
	);
};
