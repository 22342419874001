import React from 'react';
import { useFormContext } from 'react-hook-form';
import { TGalleryImages, TItemImage } from 'legacy/lib/api/types/ItemImages';
import ThumbnailGallery from 'legacy/app/components/ThumbnailGallery/ThumbnailGallery';
import ImageDragAndDrop from 'legacy/app/components/ImageDragAndDrop/ImageDragAndDrop';

const ImageGallery = () => {
	const methods = useFormContext();
	const allImages = methods.watch('images');
	const selectedImage = methods.watch('selectedImage');

	const { setValue, watch } = methods;

	const handleDrop = (file: File) => {
		if (selectedImage?.index !== null) {
			const currentImages = (watch('images') as TItemImage[]) || [];

			const updatedImages = currentImages?.map((image, index) => {
				if (index === selectedImage?.index) {
					return { imageFile: file, fileId: null };
				}

				return image;
			});

			if (selectedImage.index >= currentImages.length) {
				updatedImages.push({ imageFile: file, fileId: null });
			}
			setValue('images', updatedImages);
			setValue('selectedImage', { file, index: selectedImage?.index || 0 });
		}
	};

	const setSelectedImage = (value: TGalleryImages) =>
		setValue('selectedImage', value);

	const handleDeleteImage = (index: number) => {
		allImages?.splice(index, 1);
		const selectedImageFile = allImages?.[index]?.imageFile;
		setValue('selectedImage', { file: selectedImageFile, index: index });
	};

	return (
		<div className="tw-flex-1 lg:tw-flex-[0.6] xl:tw-flex[0.5]">
			<div className="tw-flex tw-flex-col tw-justify-between tw-h-full">
				<ImageDragAndDrop
					image={selectedImage?.file || null}
					title="Add Photo"
					subtitle="or Drag and Drop"
					iconClass="tw-text-5xl tw-font-normal"
					handleFile={(file: File) => {
						handleDrop(file);
					}}
					handleDelete={handleDeleteImage}
					imageIndex={selectedImage?.index || 0}
				/>
				<ThumbnailGallery
					galleryImages={allImages as TItemImage[]}
					setSelectedImage={setSelectedImage}
					selectedImageIndex={selectedImage?.index || 0}
				/>
			</div>
		</div>
	);
};

ImageGallery.displayName = 'ImageGallery';

export default ImageGallery;
