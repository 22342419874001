import React from 'react';
import BaseDropdown, { TBaseDropdownProps } from './BaseDropdown';
import { useGetClientInvoices } from 'api/hooks/useGetClientInvoices';

const ClientInvoicesDropdown = (
	props: TBaseDropdownProps & {
		projectId: string;
	}
) => {
	const { data, isLoading } = useGetClientInvoices('?$orderby=invnum asc');

	const options =
		data?.map((option) => ({
			label: `${option.value ?? ''} ${
				option.key ? `[${option.key}]` : ''
			}`.trim(),
			value: option.key,
		})) || [];

	return <BaseDropdown {...props} isLoading={isLoading} options={options} />;
};

ClientInvoicesDropdown.displayName = 'ClientInvoicesDropdown';

export default ClientInvoicesDropdown;
