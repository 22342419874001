import React from 'react';
import { formatCurrency } from 'legacy/utilities/formatCurrency';

interface ICheckbookReconcileStatusItemProps {
	heading: string;
	checksCounter: number;
	checksTotal: number;
	depositsCounter: number;
	depositsTotal: number;
}

const CheckbookReconcileStatusItem = ({
	heading,
	checksCounter,
	checksTotal,
	depositsCounter,
	depositsTotal,
}: ICheckbookReconcileStatusItemProps) => {
	return (
		<div className="tw-grid tw-grid-flow-row tw-gap-2">
			<span>{heading}</span>
			<div className="tw-grid tw-grid-flow-row tw-text-base">
				<div className="tw-grid tw-grid-flow-col tw-gap-4 tw-items-center tw-justify-between">
					<span className="">
						Checks{' '}
						<span className="tw-text-sm tw-font-semibold">
							({checksCounter})
						</span>
					</span>
					<span className="tw-text-sm tw-font-bold">
						{formatCurrency(checksTotal)}
					</span>
				</div>
				<div className="tw-grid tw-grid-flow-col tw-items-center tw-justify-between">
					<span>
						Deposits{' '}
						<span className="tw-text-sm tw-font-semibold">
							({depositsCounter})
						</span>
					</span>
					<span className="tw-text-sm tw-font-bold">
						{formatCurrency(depositsTotal)}
					</span>
				</div>
			</div>
		</div>
	);
};

CheckbookReconcileStatusItem.displayName = 'CheckbookReconcileStatusItem';

export { CheckbookReconcileStatusItem };
