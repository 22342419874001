import React, { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import Select from 'react-select';
import { getRemarks } from 'api/resources/remarks';
import { displayAlertError } from 'legacy/utilities/Response';

const getMappedRemarks = async () => {
	const remarks = await getRemarks();
	return remarks.map((remark) => ({
		value: remark.code,
		label: `[${remark.code}] ${remark.remarks1}`,
	}));
};

const RemarksDropdown = (props) => {
	const { data, isLoading, error } = useQuery({
		queryKey: ['dropdowns', 'remarks'],
		queryFn: getMappedRemarks,
		refetchInterval: false,
		refetchOnWindowFocus: false,
		initialData: [],
	});

	useEffect(() => {
		if (error) {
			displayAlertError(
				'There was an error fetching the Remarks information. Please try again later.'
			);
		}
	}, [error]);

	const options = isLoading ? [{ label: 'Loading...', value: '' }] : data;

	return (
		<Select {...props} options={options} isDisabled={isLoading || !!error} />
	);
};

export default RemarksDropdown;
