import React, { useEffect } from 'react';
import { useSession } from '../../context/session';

export default function Heap() {
	const {
		email,
		userId,
		username,
		companyName,
		clientId,
		clientName,
		clientSerialCode,
		fullName,
		isTrial,
		isWebOnly,
	} = useSession();

	useEffect(() => {
		if (window.heap?.loaded) {
			return;
		}
		if (
			!process.env.REACT_APP_HEAP_ID ||
			!username ||
			process.env.NODE_ENV !== 'production'
		)
			return;

		(window.heap = window.heap || []),
			(window.heap.load = function (e, t) {
				(window.heap.appid = e), (window.heap.config = t = t || {});
				var r = document.createElement('script');
				(r.type = 'text/javascript'),
					(r.async = !0),
					(r.src = 'https://cdn.heapanalytics.com/js/heap-' + e + '.js');
				var a = document.getElementsByTagName('script')[0];
				a.parentNode.insertBefore(r, a);
				for (
					var n = function (e) {
							return function () {
								window.heap.push(
									[e].concat(Array.prototype.slice.call(arguments, 0))
								);
							};
						},
						p = [
							'addEventProperties',
							'addUserProperties',
							'clearEventProperties',
							'identify',
							'resetIdentity',
							'removeEventProperty',
							'setEventProperties',
							'track',
							'unsetEventProperty',
						],
						o = 0;
					o < p.length;
					o++
				)
					window.heap[p[o]] = n(p[o]);
			});
		window.heap.load(process.env.REACT_APP_HEAP_ID);
		window.heap.identify(username);
		const userProperties = {
			appVersion: process.env.REACT_APP_HASH,
			userId,
			username,
			name: fullName,
			email,
			companyName,
			clientId,
			clientName,
			clientCode: clientSerialCode,
			trial: isTrial,
			webOnly: isWebOnly,
		};

		window.heap.addUserProperties(userProperties);
	}, [
		userId,
		username,
		email,
		clientId,
		clientName,
		fullName,
		isTrial,
		isWebOnly,
		clientSerialCode,
		companyName,
	]);

	return null;
}
