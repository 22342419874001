import React, { useState, useEffect, useRef } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { Form, Button, Row, Col, Alert } from 'react-bootstrap';
import { ApiService } from '../../../../lib/api/HttpService';
import { displayAlertLoader } from '../../../../utilities/Response';
import './InventoryItemForm.css';
import { EAlertTypes } from '../../../enums/alertTypes/alertTypes';
import { InventoryTypes } from '../../../enums/inventoryTypes/inventoryTypes';
import useGetCompany from '../../../../lib/api/hooks/useGetCompany';
import useGetCompanyLabel from 'legacy/lib/api/hooks/useGetCompanyLabel';
import { InventorySelect } from 'app/widgets/Selects/InventorySelect';
import { WarehouseSelect } from 'app/widgets/Selects/WarehouseSelect';
import { LocationSelect } from 'app/widgets/Selects/LocationSelect';
import { ICreateItemFromInventoryParams } from 'legacy/lib/api/types/InventoryItem';
import useGetFile from 'legacy/lib/api/hooks/useGetFile';
import { usePatchComponent } from 'api/hooks/usePatchComponent';
import usePageReload from 'legacy/lib/api/hooks/usePageReload';
import { Component } from 'legacy/lib/api/types/Component';
import { TStockItem } from 'legacy/lib/api/types/StockItem';
import { formatDescription } from 'legacy/app/pages/Items/utils/formatRTF';
import getItemOrMainComponentDescription from 'legacy/utilities/getItemOrMainComponentDescription';
import { Item } from 'legacy/lib/api/types/Item';

interface ISelectOption {
	label: string;
	value: string;
	name: string;
	id: number;
}

interface IFormFields {
	stockNo: string | ISelectOption;
	quantity: number;
	warehouse: string | ISelectOption;
	location: string | ISelectOption;
	useInventorySellPrice: number;
}

interface IResponseMessage {
	message?: string;
	type?: EAlertTypes;
}

interface IInventoryItemFormProps {
	closeModal: (response?: IResponseMessage) => void;
	locationQuery: string;
	projectId: string;
	type: InventoryTypes;
	itemId?: string;
	submitMethods?: (
		customRedirectPath?: string,
		getEditedItem?: (item: Item) => void
	) => Promise<void>;
	firstComponent?: Component;
}

const api = new ApiService();

const InventoryItemForm = ({
	closeModal,
	projectId,
	type = InventoryTypes.Item,
	itemId,
	submitMethods,
	firstComponent,
}: IInventoryItemFormProps) => {
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const selectedStockItem = useRef<TStockItem | null>(null);
	const createdItemRef = useRef<Item | null>(null);
	const [isOverCommitted, setIsOverCommitted] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const overCommittedMesage = `By clicking 'Ok' you can try to create the ${type} regardless.`;
	const { data } = useGetCompany();
	const { data: companyLabel } = useGetCompanyLabel(data?.whshipto || '');
	const [stockImage, setStockImage] = useState<Blob | MediaSource | null>(null);
	const [primaryImageId, setPrimaryImageId] = useState<string | null>(null);
	const { mutateAsync: patchComponent } = usePatchComponent();
	const reloadPage = usePageReload();

	const { data: imageBlob, isFetching: isFetchingImage } = useGetFile(
		primaryImageId as string,
		{
			enabled: !!primaryImageId,
		}
	);

	const { register, handleSubmit, watch, control, setValue } =
		useForm<IFormFields>({
			defaultValues: {
				useInventorySellPrice: 1,
			},
		});

	const allValues = watch();

	const onSubmit: SubmitHandler<IFormFields> = async (data) => {
		if (type === InventoryTypes.Component && submitMethods) {
			await submitMethods('', (item) => (createdItemRef.current = item));
		}

		const params = {
			stockNo: data?.stockNo,
			whCode: data.warehouse,
			newQty: data?.quantity,
		};

		setIsLoading(true);

		try {
			const { isOverCommitted, message } = await api.checkInventoryOnHand(
				params as {
					stockNo: string;
					whCode: string;
					newQty: number;
				}
			);
			if (isOverCommitted) {
				setErrorMessage(message);
				setIsOverCommitted(true);
			} else {
				await handleProceed();
				setErrorMessage(null);
			}
		} catch (error: unknown) {
			const message = 'Error checking inventory';
			closeModal({ message, type: EAlertTypes.Danger });
		} finally {
			setIsLoading(false);
		}
	};

	const quantityRegister = register('quantity');

	const handleProceed = async () => {
		const payload = {
			stockNo: allValues.stockNo,
			warehouseCode: allValues.warehouse,
			locationCode: allValues?.location,
			projectId: projectId,
			useInventorySellPrice: Boolean(allValues.useInventorySellPrice),
			strGroupName: '',
			compQty: allValues?.quantity || 0,
			...(type === InventoryTypes.Component && {
				AddCompToExistingItemId: itemId,
			}),
		};

		setIsLoading(true);

		try {
			displayAlertLoader(`Creating ${type} from Inventory`);
			const { primaryImageId, newCompId } = await api.createItemFromInventory(
				payload as ICreateItemFromInventoryParams
			);

			if (type === InventoryTypes.Component) {
				const payload: Partial<Component> = {
					id: newCompId,
					...(primaryImageId !== '' && { primaryImageId, copyitempict: false }),
				};

				const stockItemName = createdItemRef.current?.itemName || '';

				const stockItemDescriptionName =
					selectedStockItem.current?.itemName || '';

				const descHtml = await formatDescription(
					selectedStockItem.current?.descrtf || ''
				);

				const descriptionToFormat = `${
					stockItemDescriptionName ? `<p>${stockItemDescriptionName}</p>` : ''
				}${descHtml}`.trim();

				const componentDescription = await getItemOrMainComponentDescription(
					!firstComponent
						? `${stockItemName} ${stockItemDescriptionName}`
						: stockItemDescriptionName,
					{
						value: `${selectedStockItem.current?.descOnly || ''}`.trim(),
						html: descriptionToFormat,
					}
				);

				const newPayload = {
					...payload,
					itemName: !firstComponent ? stockItemName : '',
					...componentDescription,
				};

				if (primaryImageId !== '' || firstComponent) {
					await patchComponent(newPayload);
				}
			}

			const message = `Successfully created new ${type} from Inventory.`;
			closeModal({ message, type: EAlertTypes.Success });
			if (type === InventoryTypes.Component) {
				reloadPage();
			}
		} catch (error: unknown) {
			displayAlertLoader(`Creating ${type} from Inventory`);
			if (typeof error === 'object' && error !== null && 'response' in error) {
				const responseError = error as {
					response?: { data?: { userError?: string } };
				};

				const message =
					responseError.response?.data?.userError || 'Unknown error';
				closeModal({ message, type: EAlertTypes.Danger });
			}
			if (type === InventoryTypes.Component && submitMethods) {
				reloadPage();
			}
		} finally {
			setIsOverCommitted(false);
			setErrorMessage(null);
			setIsLoading(false);
		}
	};

	const handleCancel = () => {
		closeModal();
		setIsOverCommitted(false);
	};

	const clearError = () => {
		setErrorMessage(null);
		setIsOverCommitted(false);
	};

	const handleStockImage = (primaryImageId: string | null) => {
		setStockImage(null);
		if (primaryImageId) {
			setPrimaryImageId(primaryImageId);
		}
	};

	useEffect(() => {
		if (imageBlob && !isFetchingImage) {
			setStockImage(imageBlob);
		}
	}, [imageBlob, isFetchingImage]);

	return (
		<Form onSubmit={handleSubmit(onSubmit)}>
			<Row className="tw-min-h-24">
				<Col className="col-6">
					<Form.Group controlId="formStockNo">
						<Form.Label>Stock No:</Form.Label>
						<Controller
							name="stockNo"
							control={control}
							render={({ field }) => (
								<InventorySelect
									handleSelect={(option) => {
										selectedStockItem.current = option;
										handleStockImage(option?.primaryImageId || null);
										option && field.onChange(option.stockno);
										clearError();
									}}
								/>
							)}
						/>
					</Form.Group>
				</Col>
				{stockImage && (
					<Col className="col-6 tw-flex tw-justify-center tw-h-[90px]">
						<img
							src={URL.createObjectURL(stockImage)}
							alt={'stock-item-image'}
						/>
					</Col>
				)}
			</Row>

			<Row className="tw-min-h-24">
				<Col className="col-6" xs={4}>
					<Form.Group controlId="formQuantity">
						<Form.Label>Quantity:</Form.Label>
						<Form.Control
							type="decimal"
							onChange={(e) => {
								quantityRegister.onChange(e), clearError();
							}}
							onBlur={quantityRegister.onBlur}
							ref={quantityRegister.ref}
							name={quantityRegister.name}
							className="quantity-input"
						/>
					</Form.Group>
				</Col>
				<Col>
					<Form.Group controlId="formWarehouse">
						<Form.Label>Warehouse:</Form.Label>
						<Controller
							name="warehouse"
							control={control}
							render={({ field }) => (
								<WarehouseSelect
									handleSelect={(option) => {
										option && field.onChange(option.code);
										clearError();
									}}
									defaultSelected={companyLabel?.value}
								/>
							)}
						/>
					</Form.Group>
				</Col>
			</Row>

			{type === InventoryTypes.Item && (
				<Row className="tw-min-h-24">
					<Col>
						<Form.Group controlId="formLocation">
							<Form.Label>Location:</Form.Label>
							<Controller
								name="location"
								control={control}
								render={({ field }) => (
									<LocationSelect
										handleSelect={(option) => {
											field.onChange(option?.loc);
											clearError();
										}}
										projectId={projectId}
										isClearable={true}
									/>
								)}
							/>
						</Form.Group>
					</Col>
				</Row>
			)}

			<Row className="tw-min-h-24">
				<Col>
					<Form.Group>
						<Form.Label>Pricing:</Form.Label>
						<div key={`inline-radio`} className="mb-3">
							<div key={`inline-radio`} className="mb-3">
								<Form.Check
									inline
									label="Use inventory Sell Price"
									type="radio"
									id={`inline-radio-1`}
									onChange={() => setValue('useInventorySellPrice', 1)}
									checked={watch('useInventorySellPrice') === 1}
									name="useInventorySellPrice"
									value={1}
									className="me-4"
								/>
								<Form.Check
									inline
									label="Use Project Pricing Calculation"
									type="radio"
									id={`inline-radio-2`}
									onChange={() => setValue('useInventorySellPrice', 0)}
									checked={watch('useInventorySellPrice') === 0}
									name="useInventorySellPrice"
									value={0}
								/>
							</div>
						</div>
					</Form.Group>
				</Col>
			</Row>
			{!isOverCommitted && (
				<Row className="w-100">
					<Col className="d-flex" lg={{ span: 10, offset: 1 }}>
						<Button
							variant="primary"
							className="w-100 m-2"
							type="button"
							onClick={handleSubmit(onSubmit)}
							disabled={!allValues.stockNo || !allValues.warehouse || isLoading}
						>
							Ok
						</Button>

						<Button
							type="button"
							variant="trans-light border-secondary-ash"
							onClick={handleCancel}
							className="w-100 m-2"
							disabled={isLoading}
						>
							Cancel
						</Button>
					</Col>
				</Row>
			)}
			<div>
				{errorMessage && (
					<Row className="mb-3">
						<Alert variant="danger">
							{errorMessage} {overCommittedMesage}
							{isOverCommitted && (
								<Col className="d-flex" lg={{ span: 10, offset: 1 }}>
									<Button
										type="button"
										variant="primary"
										onClick={handleProceed}
										className="w-100 m-2"
										disabled={isLoading}
									>
										Ok
									</Button>
									<Button
										type="button"
										variant="secondary"
										onClick={handleCancel}
										className="w-100 m-2"
										disabled={isLoading}
									>
										Cancel
									</Button>
								</Col>
							)}
						</Alert>
					</Row>
				)}
			</div>
		</Form>
	);
};

InventoryItemForm.displayName = 'InventoryItemForm';

export default InventoryItemForm;
