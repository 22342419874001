import { ColumnDef, ColumnFiltersState } from '@tanstack/react-table';
import { useGetPurchaseOrders } from 'api/hooks/useGetPurchaseOrders';
import { IPurchaseOrder } from 'api/resources/purchaseOrders';
import { formatDate } from 'legacy/helpers/Date';
import { currencyFormat } from 'legacy/helpers/Number';
import useGetProject from 'legacy/lib/api/hooks/useGetProject';
import { useEffect, useMemo, useState } from 'react';
import Table from 'legacy/app/components/newTable/Table';
import { useParams } from 'react-router-dom';
import PurchaseOrderCell from './PurchaseOrderCell';
import Spinner from 'legacy/app/components/help/Spinner';
import { displayAlertError } from 'legacy/utilities/Response';
import useGetCompanyInfo from 'legacy/lib/api/hooks/useGetCompanyInfo';
import Label from 'legacy/app/components/label/Label';
import { Button, Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { TFormValues } from '../types/TFormValues';
import classNames from 'classnames';

const PurchaseOrderSection = ({
	itemNumber,
	comp,
	disableInputs,
}: {
	disableInputs: boolean;
	itemNumber: string;
	comp: string;
}) => {
	const { id: projectId } = useParams();
	const { control } = useFormContext<TFormValues>();
	const { data: company, isLoading: isFetchingCompany } = useGetCompanyInfo();
	const {
		data: project,
		isLoading: isFetchingProjects,
		error: fetchingProjectError,
	} = useGetProject(projectId as string);

	const filter = `?$filter=projectCode eq '${encodeURIComponent(
		project?.proj as string
	)}' AND itemNumber eq '${itemNumber}' AND componentNumber eq '${comp}'`;

	const {
		data: purchaseOrders,
		isLoading: isFetchingPurchaseOrders,
		error,
	} = useGetPurchaseOrders(filter, { enabled: !!project });

	const [enableFilters, setEnableFilters] = useState(false);

	const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

	const installDateLabel = company?.wildcardstatus1 || 'Install Date';

	const inspectionDateLabel = company?.wildcardstatus2 || 'Inspection';

	useEffect(() => {
		if (error || fetchingProjectError) {
			displayAlertError(
				'There was an error fetching the Purchase Orders, please try again'
			);
		}
	}, [error, fetchingProjectError]);

	const columns = useMemo<ColumnDef<IPurchaseOrder>[]>(
		() => [
			{
				accessorKey: 'ponum',
				header: 'PO/WO NO.',
				enableSorting: false,
				cell: (info) => PurchaseOrderCell({ info }),
			},
			{
				accessorKey: 'quantity',
				header: 'Qty.',
				enableSorting: false,
				filterFn: 'equalsString',
				cell: (info) => info.getValue(),
			},
			{
				accessorFn: (col) => formatDate(col.orderDate),
				header: 'Order',
				enableSorting: false,
				cell: (info) => info.getValue(),
			},
			{
				accessorKey: 'vendor',
				header: 'Vendor',
				enableSorting: false,
				cell: (info) => info.getValue(),
			},
			{
				accessorFn: (col) => currencyFormat(col.deposit),
				header: 'Deposit',
				enableSorting: false,
				cell: (info) => info.getValue(),
			},
			{
				accessorKey: 'checkNumber',
				header: 'Dep. Ck. No.',
				enableSorting: false,
				cell: (info) => info.getValue(),
			},
			{
				accessorFn: (col) => formatDate(col.checkDate),
				header: 'CK. DT.',
				enableSorting: false,
				cell: (info) => info.getValue(),
			},
			{
				accessorFn: (col) => formatDate(col.acknowledgementDate),
				header: 'Ack. DT.',
				enableSorting: false,
				cell: (info) => info.getValue(),
			},
			{
				accessorKey: 'acknowledgementNumber',
				header: 'Ack No.',
				enableSorting: false,
				cell: (info) => info.getValue(),
			},
			{
				accessorKey: 'shipVia',
				header: 'Ship Via',
				enableSorting: false,
				cell: (info) => info.getValue(),
			},
			{
				accessorFn: (col) => formatDate(col.expectedDate),
				header: 'Expected',
				enableSorting: false,
				cell: (info) => info.getValue(),
			},
		],
		[]
	);

	if (isFetchingProjects || isFetchingPurchaseOrders || isFetchingCompany) {
		return <Spinner isChild />;
	}

	const columnFilterState = {
		columnFilters,
	};

	return purchaseOrders && company ? (
		<>
			<div className="tw-flex tw-justify-between tw-items-center tw-p-6 tw-bg-[#EEEDE9] tw-rounded-md">
				<p className="tw-text-base tw-font-bold tw-m-0">
					Purchase and Work Orders
				</p>
				<Button
					className={classNames(
						'!tw-bg-transparent !tw-border-[#dedcd7] !tw-border-[1px] !tw-text-[#211f21] !tw-text-sm !tw-font-bold',
						enableFilters && '!tw-bg-black !tw-text-white'
					)}
					size="sm"
					onClick={() => {
						const value = !enableFilters;
						setEnableFilters(value);
						if (value === false) {
							setColumnFilters([]);
						}
					}}
				>
					<i className="ri-search-line"></i> Search
				</Button>
			</div>
			<div className="tw-pt-6 tw-overflow-x-auto">
				<div className="tw-pb-24">
					<Table
						state={columnFilterState}
						onColumnFiltersChange={setColumnFilters}
						data={purchaseOrders}
						columns={columns}
						enableFilters={enableFilters}
					/>
				</div>
				<div className="tw-flex tw-gap-8 tw-pt-12 tw-pr-10 tw-pl-10 tw-justify-end">
					<div className="tw-grid tw-grid-cols-[20%_80%] tw-gap-8 tw-items-center tw-flex-1">
						<Label label="CFA Date" />
						<Controller
							control={control}
							name="cfadt"
							render={({ field }) => (
								<Form.Control
									type="date"
									disabled={disableInputs}
									value={field.value as string}
									onChange={field.onChange}
									id="CFA Date"
								/>
							)}
						/>
						<Label
							label="Status/Storage Loc."
							className="tw-whitespace-nowrap"
						/>
						<Controller
							control={control}
							name="statusnote"
							render={({ field }) => (
								<Form.Control
									disabled={disableInputs}
									value={field.value as string}
									onChange={field.onChange}
									placeholder="Please enter"
									id="Status/Storage Loc."
									className="tw-w-full"
								/>
							)}
						/>
					</div>
					<div className="tw-grid tw-grid-cols-[20%_80%] tw-gap-8 tw-items-center tw-flex-1">
						<Label label={installDateLabel} />
						<Controller
							control={control}
							name="wc1dt"
							render={({ field }) => (
								<Form.Control
									type="date"
									disabled={disableInputs}
									value={field.value as string}
									onChange={field.onChange}
									id={installDateLabel}
									className="tw-w-full"
								/>
							)}
						/>
						<Label label={inspectionDateLabel} />
						<Controller
							control={control}
							name="wc2dt"
							render={({ field }) => (
								<Form.Control
									disabled={disableInputs}
									value={field.value as string}
									onChange={field.onChange}
									type="date"
									id={inspectionDateLabel}
									className="tw-w-full"
								/>
							)}
						/>
					</div>
				</div>
			</div>
		</>
	) : null;
};

PurchaseOrderSection.displayName = 'PurchaseOrderSection';
export default PurchaseOrderSection;
