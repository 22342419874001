import React, { useEffect, useState, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { WarehouseSelect } from 'app/widgets/Selects/WarehouseSelect';
import useGetCompany from 'legacy/lib/api/hooks/useGetCompany';
import { useGetCompanyTwo } from '../../../../../api/hooks/useGetCompanyTwo';
import { Form, Button } from 'react-bootstrap';
import { FooterFormAction } from 'legacy/templates/components/Form';
import { TCompany2 } from 'legacy/lib/api/types/Company2';
import { Company } from 'legacy/lib/api/types/Company';
import { displayAlert } from 'legacy/utilities/Response';
import { EAlertTypes } from 'legacy/app/enums/alertTypes/alertTypes';
import { displayAlertLoader } from 'legacy/utilities/Response';
import { usePatchCompany } from 'api/hooks/usePatchCompany';
import { usePatchCompanyTwo } from 'api/hooks/usePatchCompanyTwo';

type TFormData = Partial<TCompany2> & Partial<Company>;

export const InventorySettingsForm = () => {
	const [initialFormData, setInitialFormData] = useState<TFormData | null>(
		null
	);
	const { data: companyData, isFetching: isFetchingCompany } = useGetCompany();
	const { mutate: patchCompanyTwo } = usePatchCompanyTwo();
	const { mutate: patchCompany } = usePatchCompany();
	const { data: companyTwo, isFetching: isFetchingCompanyTwo } =
		useGetCompanyTwo();
	const { whshipto } = companyData || {};
	const [formIsDirty, setFormIsDirty] = useState(false);

	const {
		invporemarks,
		inventoryupdatecost,
		invposhowrefnum,
		invmupct,
		dcLabelFormatId,
	} = companyTwo || {};

	const { register, handleSubmit, setValue, control, watch } =
		useForm<TFormData>();

	const allValuesRef = useRef<TFormData | null>(null);

	useEffect(() => {
		const subscription = watch((values) => {
			allValuesRef.current = values;
			if (initialFormData) {
				const hasChanges = Object.keys(values).some(
					(key) =>
						values[key as keyof TFormData] !=
						initialFormData[key as keyof TFormData]
				);
				setFormIsDirty(hasChanges);
			}
		});
		return () => subscription.unsubscribe();
	}, [watch, initialFormData]);

	useEffect(() => {
		if (
			!isFetchingCompanyTwo &&
			companyTwo &&
			!isFetchingCompany &&
			!initialFormData
		) {
			const initialData: TFormData = {
				whshipto: whshipto || '',
				invmupct: invmupct || 0,
				invporemarks: invporemarks || '',
				inventoryupdatecost: inventoryupdatecost || false,
				invposhowrefnum: invposhowrefnum || false,
				dcLabelFormatId: dcLabelFormatId || null,
			};
			setInitialFormData(initialData);
			setValue('whshipto', whshipto || '');
			setValue('invmupct', invmupct || 0);
			setValue('invporemarks', invporemarks || '');
			setValue('inventoryupdatecost', inventoryupdatecost || false);
			setValue('invposhowrefnum', invposhowrefnum || false);
			setValue('dcLabelFormatId', dcLabelFormatId || null);
		}
	}, [
		companyTwo,
		whshipto,
		invmupct,
		invporemarks,
		inventoryupdatecost,
		invposhowrefnum,
		dcLabelFormatId,
		isFetchingCompanyTwo,
		setValue,
		initialFormData,
		isFetchingCompany,
	]);

	const getChangedFields = (currentData: TFormData, initialData: TFormData) => {
		const changedFields: TFormData = {};
		for (const key in currentData) {
			if (
				currentData[key as keyof TFormData] !==
				initialData[key as keyof TFormData]
			) {
				changedFields[key as keyof TFormData] = currentData[
					key as keyof TFormData
				] as any;
			}
		}
		return changedFields;
	};

	const onSubmit = (currentData: TFormData) => {
		try {
			displayAlertLoader('Saving changes...');
			const changedFields: TFormData = initialFormData
				? getChangedFields(currentData, initialFormData)
				: {};

			const { whshipto, ...companyTwoData } = changedFields;

			if (Object.keys(companyTwoData).length > 0) {
				patchCompanyTwo(companyTwoData as TCompany2, {
					onSuccess: () => {
						displayAlert(EAlertTypes.Success, 'Updated Successfully');
						setInitialFormData((initialFormData) => ({
							...initialFormData,
							...companyTwoData,
						}));
					},
					onError: () => {
						displayAlert(
							EAlertTypes.Danger,
							'Error updating Company Warehouse'
						);
					},
				});
			}

			if (whshipto) {
				patchCompany({ whshipto } as Company, {
					onSuccess: () => {
						displayAlert(EAlertTypes.Success, 'Updated Successfully');
						setInitialFormData((initialFormData) => ({
							...initialFormData,
							whshipto,
						}));
					},
					onError: () => {
						displayAlert(EAlertTypes.Danger, 'Error updating Company');
					},
				});
			}
			setFormIsDirty(false);
		} catch (error: unknown) {
			displayAlert(EAlertTypes.Danger, 'Error while saving changes');
		}
	};
	return (
		<div>
			<div className="tw-p-[30px] tw-px-[40px] tw-bg-white min-height ">
				<form id="inventoryForm">
					<div className="tw-grid tw-grid-cols-1 tw-w-full tw-gap-3 sm:tw-grid-cols-2 md:tw-grid-cols-2 md:tw-w-2/4 sm:tw-gap-6 md:tw-gap-8 tw-mb-4  ">
						<label className=" md:tw-mb-0 tw-text-nowrap tw-text-sm tw-self-center tw-justify-self-start md:tw-justify-self-end">
							Default Warehouse:
						</label>
						<div className="tw-self-center">
							<Controller
								control={control}
								name="whshipto"
								render={({ field }) => (
									<WarehouseSelect
										handleSelect={(option) =>
											field.onChange(option?.code || '')
										}
										defaultSelected={field.value}
									/>
								)}
							/>
						</div>
						<label className="md:tw-mb-0 tw-text-nowrap tw-text-sm tw-self-center tw-justify-self-start md:tw-justify-self-end">
							Default Mark-Up %:
						</label>
						<div className="tw-w-full md:tw-w-[40%] tw-self-center">
							<Form.Control
								type="decimal"
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...register('invmupct')}
								defaultValue={invmupct}
							/>
						</div>
					</div>
				</form>
			</div>
			<FooterFormAction footerClass="tw-bottom-0">
				<Button
					variant="primary"
					size="lg"
					disabled={!formIsDirty}
					onClick={handleSubmit(onSubmit)}
				>
					Save
				</Button>
			</FooterFormAction>
		</div>
	);
};

InventorySettingsForm.displayName = 'InventorySettingsForm';
