import { apiClient } from 'api/apiClient';
import type { Summary } from 'legacy/lib/api/types/Summary';

const RESOURCE = '/ClientInvoices/summaries';

export const getClientInvoices = async (
	filter?: string
): Promise<Summary[]> => {
	const { data } = await apiClient.get(`${RESOURCE}${filter ?? ''}`.trim());

	return data.results;
};
