import { apiClient } from 'api/apiClient';
import type { TStockItem } from 'legacy/lib/api/types/StockItem';
import { fetchThumbnail } from 'legacy/templates/modules/stock-items/StockItemsService';

const RESOURCE = '/inventoryStocks';

export const getInventoryStocks = async (
	filter = '',
	withImages = false
): Promise<TStockItem[]> => {
	const { data } = await apiClient.get(`${RESOURCE}${filter}`.trim());
	const items = await Promise.all(
		data.map(async (item: TStockItem) => {
			if (withImages && item.primaryImageId) {
				const stockImage = await fetchThumbnail(item.primaryImageId);
				return { ...item, stockImage };
			}
			return item;
		})
	);
	return items;
};
