export enum FeatureFlags {
	TaxModal = 'TAX_MODAL',
	CheckbookReconcile = 'CHECKBOOK_RECONCILE',
	ProjectProposalStatus = 'PROJECT_PROPOSAL_STATUS',
	SpecificationTemplates = 'SPECIFICATION_TEMPLATES',
	ProjectSchedule = 'PROJECT_SCHEDULE',
	Reporting = 'REPORTING',
	BudgetInputs = 'BUDGET_INPUTS',
	FinanceCharges = 'FINANCE_CHARGES',
	PLAID = 'PLAID',
	Transactions = 'TRANSACTIONS',
	EnableReorder = 'ENABLE_REORDER',
}
