import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import {
	get1099VendorDefaults,
	type IVendorDefaults,
} from 'api/resources/1099VendorDefaults';
import { ECacheKeys } from 'cache/CacheKeys';

export const useGet1099VendorDefaults = (
	options?: UseQueryOptions<IVendorDefaults>
) => {
	return useQuery<IVendorDefaults>({
		queryKey: [ECacheKeys.VendorDefaults],
		refetchInterval: false,
		refetchOnWindowFocus: false,
		queryFn: get1099VendorDefaults,
		...(options || {}),
	});
};
