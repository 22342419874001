import { Helmet } from 'react-helmet';
import { Form } from 'react-bootstrap';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useUsercode, useRunReport } from 'legacy/hooks';
import ConfirmModal from './ConfirmModal';
import ConfirmationMessage from './ConfirmationMessage';
import Grid from './Grid';
import { ReconcileSetup } from './ReconcileSetup';
import ReconcileStatus from './ReconcileStatus';
import { ReconcileHeader } from './ReconcileHeader';
import ReconcileTabs from './ReconcileTabs';
import { TransactionDetailsModal } from './TransactionDetailsModal/TransactionDetailsModal';
import {
	acceptReconciliation,
	editCreditCardFinanceCharges,
	getGridData,
	getTemporarycreditcardreconciliationTotals,
	updateTemporarycreditcardreconciliation,
} from './api';
import WarningMessage from './WarningMessage';
import SecureContent from 'legacy/app/components/security/SecureContent';
import { SECURITY_ATTRIBUTE_TYPES } from 'legacy/app/context/security';
import { useHeader } from 'legacy/hooks/useHeader';
import URI from 'legacy/defaults/RoutesDefault';
import { FinanceCharge } from './FinanceCharge/FinanceCharge';
import { ECacheKeys } from 'cache/CacheKeys';
import dayjs from 'dayjs';
import { useReconcile } from './hooks/useReconcile';

const fetchTotals = async (setup) => {
	const {
		endingBalance,
		statementDate,
		account: { value },
	} = setup;
	if (typeof endingBalance === 'undefined' || !statementDate || !value) {
		return Promise.resolve(null);
	}

	return await getTemporarycreditcardreconciliationTotals({
		CreditCardAccount: value,
		DoClearFlags: true,
		DoSumFlags: true,
		StatementBalance: endingBalance,
		StatementEndingDate: statementDate,
	});
};

const CreditCardReconcile = () => {
	const {
		tab,
		setTab,
		selected,
		setSelected,
		check,
		setCheck,
		started,
		setStarted,
	} = useReconcile();
	const [isConfirmationShowing, setIsConfirmationShowing] = useState(false);
	const [isShowingDownloadModal, setIsShowingDownloadModal] = useState(false);
	const [isWarningShowing, setIsWarningShowing] = useState(false);
	const [reconcileSetup, setReconcileSetup] = useState(null);
	const [searching, setSearching] = useState(false);
	const [showCleared, setShowCleared] = useState(false);
	const [transactionDetails, setTransactionDetails] = useState(null);
	const [financeChangesModal, setFinanceChangesModal] = useState(false);

	const editFinanceCharges = useQuery({
		queryKey: [ECacheKeys.CreditCard, ECacheKeys.FinanceCharges],
		queryFn: () => {
			return editCreditCardFinanceCharges({
				account: reconcileSetup?.account.value,
				statementEndingDate: reconcileSetup?.statementDate,
			});
		},
		enabled: financeChangesModal,
		refetchOnWindowFocus: false,
		staleTime: 0,
		retry: 0,
	});

	const userCode = useUsercode();
	const runReport = useRunReport();
	const queryClient = useQueryClient();

	useHeader({
		title: 'Credit Card',
		breadcrumbs: [
			{
				text: 'Accounts Payable',
				to: URI.accountsPayable.creditCard.list,
			},
			{
				text: 'Credit Card',
			},
		],
		tabs: [
			{
				label: 'Reconcile',
				to: URI.accountsPayable.creditCard.reconcile,
				active: true,
				id: 'credit-card-reconcile',
			},
			{
				label: 'Reconcile History',
				to: URI.accountsPayable.creditCard.history,
				active: false,
				id: 'credit-card-reconcile-history',
			},
		],
		enableBackButton: false,
	});

	const mutation = useMutation(updateTemporarycreditcardreconciliation, {
		onSuccess: ({ recnum }) => {
			queryClient.invalidateQueries(['accounts-payable', 'credit-card']);
			setSelected((prevState) => ({
				...prevState,
				[recnum]: false,
			}));
			setCheck({ outstanding: false, cleared: false });
		},
	});

	const acceptMutation = useMutation(acceptReconciliation, {
		onSuccess: () => {
			queryClient.invalidateQueries(['accounts-payable', 'credit-card']);
			resetReconciliation();
		},
	});

	const totalsQuery = useQuery(
		['accounts-payable', 'credit-card', 'totals'],
		() => fetchTotals(reconcileSetup),
		{ enabled: started }
	);

	const gridQuery = useQuery(
		['accounts-payable', 'credit-card', 'grid'],
		getGridData,
		{ enabled: started }
	);

	const onConfirmationConfirm = () => {
		/*
		 * After click on Yes, call
		 * [POST]/api/v1/temporarycreditcardreconciliations/accept
		 * Body:
		 * {
		 *   CreditCardAccount : string
		 *   StatementEndingDate : DateTime
		 *   StatementBalance : decimal
		 * }
		 */
		acceptMutation.mutate({
			creditCardAccount: reconcileSetup.account.value,
			statementEndingDate: reconcileSetup.statementDate,
			statementBalance: reconcileSetup.endingBalance,
			balance: totalsQuery.data.balance,
			computerBalance: totalsQuery.data.computerBalance,
		});

		setIsConfirmationShowing(false);
	};

	const onConfirmationCancel = () => {
		setIsConfirmationShowing(false);
	};

	const onDownload = () => {
		setIsShowingDownloadModal(true);
	};

	const onDownloadConfirm = () => {
		const statementEndingDate = dayjs(
			new Date(reconcileSetup.statementDate)
		).format('YYYY-MM-DD');

		// "value": "AccountNumber,StatementEndingDate,StatementEndingBalance,Balance,ComputerBalance,ShowCleared,UserCode"
		const value = [
			reconcileSetup.account.value, // AccountNumber
			statementEndingDate, // StatementEndingDate
			reconcileSetup.endingBalance, // StatementEndingBalance
			totalsQuery.data.balance, // Balance
			totalsQuery.data.computerBalance, // ComputerBalance
			showCleared, // ShowCleared
			userCode, // UserCode
		].join(',');

		runReport({
			label: 'Reconcile Credit Card Preview',
			reportId: 1177,
			reportFormatId: 1353,
			runReportParameters: [
				{
					parameterId: 735,
					value,
				},
			],
		});
	};

	const onFinanceChargesClick = () => {
		setFinanceChangesModal((prev) => !prev);
	};

	const onMarkCleared = () => {
		Object.keys(selected).forEach((recnum) => {
			if (!selected[recnum]) {
				return;
			}
			if (
				!gridQuery.data.some((x) => x.recnum === Number(recnum) && !x.cleared)
			) {
				return;
			}

			mutation.mutate({ recnum, cleared: true });
		});
	};

	const onMarkUncleared = () => {
		Object.keys(selected).forEach((recnum) => {
			if (!selected[recnum]) {
				return;
			}
			if (
				!gridQuery.data.some((x) => x.recnum === Number(recnum) && x.cleared)
			) {
				return;
			}

			mutation.mutate({ recnum, cleared: false });
		});
	};

	const onAcceptReconcile = () => {
		if (!totalsQuery.isLoading && totalsQuery.data.difference !== 0) {
			setIsWarningShowing(true);
		} else {
			setIsConfirmationShowing(true);
		}
	};

	const onReconcileStart = (data) => {
		setReconcileSetup(data);
		setStarted(true);
	};

	const onSearch = () => {
		setSearching((prev) => !prev);
	};

	const onSelect = (event) => {
		const { name, checked } = event.target;

		setSelected((prevState) => ({
			...prevState,
			[name]: checked,
		}));

		// Uncheck 'check all' option when a single option is unchecked.
		if (!checked) {
			if (check.outstanding) {
				setCheck((prevState) => ({ ...prevState, outstanding: false }));
			} else if (check.cleared) {
				setCheck((prevState) => ({ ...prevState, cleared: false }));
			}
		}
	};

	const onSelectAll = (event) => {
		let filteredItems = [];

		if (tab === 'cleared') {
			filteredItems = gridQuery.data.filter((item) => item.cleared);
		} else {
			filteredItems = gridQuery.data.filter((item) => !item.cleared);
		}

		const selected = filteredItems.reduce((acc, cur) => {
			acc[cur.recnum] = event.target.checked;
			return acc;
		}, {});

		setSelected((prevState) => ({ ...prevState, ...selected }));
	};

	const onTransactionDetail = (transaction) => {
		setTransactionDetails(transaction);
	};

	const onWarningCancel = () => {
		setIsWarningShowing(false);
	};

	const onWarningConfirm = () => {
		setIsWarningShowing(false);
		setIsConfirmationShowing(true);
	};

	const resetReconciliation = () => {
		setStarted(false);
		setReconcileSetup({
			endingBalance: null,
			statementDate: null,
		});
		setTab('outstanding');
		setSearching(false);
		setSelected({});
	};

	const gridData = gridQuery.isLoading ? [] : gridQuery.data;

	return (
		<>
			<Helmet>
				<title>Reconcile Credit Card | Design Manager</title>
			</Helmet>
			<SecureContent
				attributeNo={61}
				attributeType={SECURITY_ATTRIBUTE_TYPES.DenyAccess}
			>
				<SecureContent.NoAccess>
					<div className="content-padding min-height has-action-bar mt-2">
						<SecureContent.GenericNoAccess />
					</div>
				</SecureContent.NoAccess>
				<SecureContent.HasAccess>
					<ReconcileSetup
						onStart={onReconcileStart}
						onDownload={onDownload}
						onAccept={onAcceptReconcile}
						data={gridData}
					/>
					{!started && (
						<div className="container mt-4">
							Select an{' '}
							<span className="bg-ivory border-1 border-sand roundedx-4">
								Account
							</span>
							,{' '}
							<span className="bg-ivory border-1 border-sand roundedx-4">
								Statement date
							</span>{' '}
							and{' '}
							<span className="bg-ivory border-1 border-sand roundedx-4">
								Statement Ending balance
							</span>{' '}
							to start the reconciliation.
						</div>
					)}
					{started && (
						<>
							<ReconcileStatus totalsQuery={totalsQuery} />
							<ReconcileTabs />
							<div className="content-padding min-height has-action-bar mt-2">
								<ReconcileHeader
									endingBalance={reconcileSetup.endingBalance}
									onFinanceChargesClick={onFinanceChargesClick}
									onMarkCleared={onMarkCleared}
									onMarkUncleared={onMarkUncleared}
									onSearch={onSearch}
									searching={searching}
									gridQuery={gridQuery}
								/>
								{!gridQuery.isLoading && (
									<Grid
										data={gridData}
										onSelect={onSelect}
										onSelectAll={onSelectAll}
										onTransactionDetail={onTransactionDetail}
										searching={searching}
									/>
								)}
							</div>

							<ConfirmModal
								isShowing={isWarningShowing}
								onConfirm={onWarningConfirm}
								onCancel={onWarningCancel}
							>
								<WarningMessage />
							</ConfirmModal>

							<ConfirmModal
								isShowing={isConfirmationShowing}
								onConfirm={onConfirmationConfirm}
								onCancel={onConfirmationCancel}
							>
								<ConfirmationMessage />
							</ConfirmModal>

							{editFinanceCharges?.data?.newTxnum && (
								<FinanceCharge
									show={financeChangesModal}
									onToggleModal={onFinanceChargesClick}
									account={reconcileSetup.account}
									statementDate={reconcileSetup.statementDate}
									fiscalMonth={editFinanceCharges?.data.fiscalMonth}
									isRevision={editFinanceCharges?.data.isRevision}
								/>
							)}

							<ConfirmModal
								isShowing={isShowingDownloadModal}
								onConfirm={onDownloadConfirm}
								onCancel={() => setIsShowingDownloadModal(false)}
							>
								<Form.Check
									type="checkbox"
									id="show-cleared"
									label="Show Cleared Entries?"
									className="rounded"
									onChange={(e) => setShowCleared(e.target.checked)}
								/>
							</ConfirmModal>
							{!!transactionDetails && (
								<TransactionDetailsModal
									onClose={() => setTransactionDetails(null)}
									type={transactionDetails.type}
									txnum={transactionDetails.txnum}
								/>
							)}
						</>
					)}
				</SecureContent.HasAccess>
			</SecureContent>
		</>
	);
};

CreditCardReconcile.displayName = 'CreditCardReconcile';

export { CreditCardReconcile };
