import PurchaseOrderSection from './PurchaseOrderSection';
import VendorInvoicesSection from './VendorInvoicesSection';

const OrderStatusTab = ({
	itemNumber,
	disableInputs = false,
	comp,
}: {
	disableInputs?: boolean;
	itemNumber: string;
	comp: string;
}) => {
	return (
		<div className="tw-min-h-[calc(100vh-360px)] tw-pt-10 tw-pb-10 tw-pl-8 tw-pr-8 tw-flex tw-flex-col tw-gap-12">
			<div>
				<PurchaseOrderSection
					itemNumber={itemNumber}
					comp={comp}
					disableInputs={disableInputs}
				/>
			</div>
			<div className="tw-pb-24">
				<VendorInvoicesSection itemNumber={itemNumber} comp={comp} />
			</div>
		</div>
	);
};

OrderStatusTab.displayName = 'OrderStatusTab';

export default OrderStatusTab;
