import React from 'react';
import { generatePath, Link, NavLink } from 'react-router-dom';
import { Breadcrumb, Button, Col, Container, Row } from 'react-bootstrap';
import ThreadComponent from '../../ThreadComponent';
import {
	getActiveMenuUri,
	getRouteWithParam,
	routeParam,
	WithRouter,
} from '../../../../helpers/Router';
import { compareStr } from '../../../../helpers/String';
import URI from '../../../../defaults/RoutesDefault';
import { HeaderLight } from '../../../components/Header';
import { getSubComponent, Is, isFiltered } from '../../../../helpers/Util';
import ProjectProposalAddProposal from './ProjectProposalAddProposal';
import { FooterFormAction } from '../../../components/Form';
import ProjectProposalAddRemarks from './ProjectProposalAddRemarks';
import ProjectProposalAddAdditionalDeposit from './ProjectProposalAddAdditionalDeposit';
import { ApiService } from '../../../../lib/api/HttpService';
import { getSaveButtonName } from '../../../../utilities/String';
import {
	getLocalStorage,
	setLocalStorage,
} from '../../../../utilities/LocalStorage';
import {
	displayAlert,
	handleResponseAlert,
} from '../../../../utilities/Response';
import dayjs from 'dayjs';
import {
	setDraft,
	setLocalStorageKeyValueObject,
} from '../../../../utilities/FormEvent';
import HandleDeleteWorker from '../../../../utilities/DeleteWorker';
import DeleteModal from '../../../../app/components/modal/DeleteModal';
import NotFoundPage from '../../../../app/pages/Home/NotFoundPage';
import Spinner from '../../../../app/components/help/Spinner';
import { tableSearch } from '../../../../utilities/modules/TableSearch';
import { dateToPayload, formatDate } from '../../../../helpers/Date';
import { SECURITY_ATTRIBUTE_TYPES } from '../../../../app/context/security';
import SecureBootstrapButton from '../../../../app/components/security/SecureBootstrapButton';

class ProjectProposalAdd extends ThreadComponent {
	constructor(props) {
		super(props);
		this.state = {
			activeMenu: getActiveMenuUri(
				this.props.paths.pathname,
				['proposal', 'remarks', 'additional-deposit'],
				'proposal'
			),
			proposalToEdit: null,
			proposal: {
				projectItems: [],
				propdate: dayjs(new Date()).format('YYYY-MM-DD'),
				adddepdt: null,
				availableItems: [],
				proposalItems: [],
			},
			initialProposal: null,
			proposalTypeDefault: null,
			authToken: '',
			statusCodes: [],
			activeItems: [],
			filteredActiveItems: [],
			projectItems: [],
			projectItemsUnselected: [],
			showAllItems: false,
			isLoading: false,
			dataIsLoaded: false,
			defaultData: {
				proj: '',
				propname: 'NA',
				propdate: dayjs(new Date()).format('YYYY-MM-DD'),
				propStyle: 0,
				setInactive: false,
				setCompleted: false,
				remarks: '',
				remarksrtf: '',
				addDepAmt: 0,
				adddepdt: null,
				showActDep: false,
				itemNoArray: [],
				reportFormatId: 0,
				requestedDeposit: 0,
				receivedDeposit: 0,
				lastPaymentDate: '',
				lastNotificationId: null,
			},
			projectName: '',
			projectOneproposal: '',
			isNotFound: false,
			filters: {},
			sorters: {},
			propnum: '',
		};

		this.updatedData = {};
		this.originalPropDate = null;

		this.api = new ApiService();
	}

	componentInit() {
		this.title = this.props.params.pid ? 'Edit Proposal' : 'Add Proposal';
	}

	componentDidUpdate() {
		if (this.props.params.pid) {
			this.title = `Edit Proposal ${this.state.propnum}`;
		}
	}

	async componentDidMount() {
		setLocalStorage(`project_proposal_edit`, {}, true);

		setLocalStorage('project_proposal_edit_checked_item', [], true);
		setLocalStorage('addProposalSelected', [], true);
		setLocalStorage('project_proposal_edit_proposal_type', null, true);

		const { id, pid } = this.props.params;
		let proposal = { projectItems: [] };
		const proposalId = pid;

		if (typeof proposalId !== 'undefined') {
			let filter = `?$filter=id eq ${proposalId}`;
			const response = await this.api.getProjectProposals(filter);
			this.setState(
				{
					proposalToEdit: response,
					propnum: response[0].propnum,
				},
				() => {
					if (
						this.state.propnum !== 'undefined' &&
						this.title === 'Edit Proposal'
					) {
						this.title = `Edit Proposal ${this.state.propnum}`;
					}
				}
			);

			if (response.length) {
				proposal = response[0];
				proposal.propdate = formatDate(proposal.propdate);
				proposal.adddepdt = formatDate(proposal.adddepdt);

				filter = `?$filter=proj eq '${response[0].proj}' and propnum eq '${response[0].propnum}'`;
				proposal.proposalItems = await this.api.getProposalItems(filter);
			} else {
				// Set the page not found if proposal ID does not exists.
				this.setState({
					isNotFound: true,
				});
			}
		}

		this.company = await this.api.getCompany2();

		// Get Proposal Types
		proposal.proposalTypes = await this.getProposalTypes(proposal);
		await this.setProposalTypeDefault(proposal, proposal.proposalTypes);

		let filter = `?$filter=id eq ${id}`;
		const project = await this.api.getProjects(filter);
		proposal.projectRemarksRtf = project[0].propremarksrtf;
		proposal.projectRemarks = project[0].propremarks;
		proposal.projectName = project[0].projn;
		proposal.projectCode = project[0].proj;

		if (project) {
			const projectName = `${project[0].projn} [${project[0].proj}] - ${project[0].clientName}`;

			this.setState({
				projectName: projectName,
				projectOneproposal: project[0].oneproposal,
			});
		}

		const statusCodes = await this.api.getStatusCodes();
		this.setState(
			{
				initialProposal: proposal,
				statusCodes,
			},
			() => this.processProjectItems(proposal)
		);
	}

	processProjectItems = async (proposal) => {
		const { pid } = this.props.params;
		let queryFilters = `?$filter=proj eq '${proposal.projectCode}'`;
		if (pid === undefined) {
			queryFilters += `and inactive eq false and completed eq false`;
		}

		let projectItems = await this.getProjectItemsByFilter(queryFilters);

		const availableItems = pid === undefined ? [] : projectItems;
		let filteredProjectItems = projectItems.filter((item) => {
			return !item.inactive && !item.completed && !item.latestprpno;
		});
		filteredProjectItems = _.sortBy(filteredProjectItems, function (item) {
			return [item.locationName, item.item];
		});
		const projectItemsUnselected = this.getProjectItemsUnselected(
			filteredProjectItems,
			availableItems,
			proposal.proposalItems
		);

		proposal.projectItems = projectItems;
		proposal.availableItems = availableItems;
		proposal.projectItemsUnselected = projectItemsUnselected;
		proposal.filteredProjectItems = filteredProjectItems;

		this.renderData(proposal);
	};

	async getProjectItemsByFilter(filter) {
		const { statusCodes } = this.state;
		const projectItems = await this.api.getProjectItems(filter);
		for (let item of projectItems) {
			const statusNumber = item.statusnumber;
			const statusItem = statusCodes.find(
				(item) => item.statusNumber === statusNumber
			);
			item.statusName = statusItem ? statusItem.statusName : '';
		}

		return projectItems;
	}

	async getProposalTypes(proposal) {
		let proposalTypes = await this.api.get('reportformats/types', 'proposal');
		let proposalTypesOptions = [];

		if (proposalTypes && proposalTypes.isOk) {
			proposalTypes = proposalTypes.reportFormats.filter(
				(format) => format.allowInWeb
			);
		}

		if (!proposalTypes) proposalTypes = [];
		else {
			for (let pt of proposalTypes) {
				proposalTypesOptions.push({
					value: pt.id,
					label: pt.name,
				});
			}
		}

		return proposalTypesOptions;
	}

	async setProposalTypeDefault(proposal, proposalTypes) {
		let propStyle = {};
		if (proposal.style && proposalTypes[0]) {
			propStyle = proposalTypes.find((p) => p.value === proposal.style);
		} else if (!proposal.style && proposalTypes[0]) {
			propStyle = proposalTypes.filter((option) => {
				return option.value === this?.company?.propstyle;
			})[0];
		}

		if (!Is.empty(propStyle)) {
			setLocalStorage('project_proposal_edit_proposal_type', propStyle, true);

			setLocalStorageKeyValueObject(
				'project_proposal_edit',
				'propStyle',
				propStyle.value,
				true
			);

			setLocalStorageKeyValueObject(
				'project_proposal_edit',
				'reportFormatId',
				propStyle.value,
				true
			);

			this.setState({
				proposalTypeDefault: {
					value: propStyle.value,
					label: propStyle.label,
				},
			});
		}
	}

	getProjectItemsUnselected(projItems, proposalItems) {
		let theItems = [];
		let projItemsList = [];
		if (proposalItems && proposalItems.length) {
			for (let item of proposalItems) {
				const propItem = projItems.find((i) => i.id === item.itemId);
				theItems.push(propItem);
			}
		}

		if (projItems.length) {
			for (let item of projItems) {
				if (item) {
					let found = false;
					if (theItems.length) {
						for (let item2 of theItems) {
							if (item2) {
								if (item2.id === item.id) found = true;
							}
						}
					}
					if (!found) {
						projItemsList.push(item);
					}
				}
			}
		}

		return projItemsList;
	}

	async renderData(data) {
		let items = [];
		let itemArrayNo = [];
		let itemSelectedIds = [];
		let itemActiveIds = [];

		if (data.proposalItems && data.proposalItems.length > 0) {
			for (let item of data.proposalItems) {
				const propItem = data.projectItems.find((i) => i.id === item?.itemId);
				if (propItem) {
					propItem.isChecked = true;
					items.push(propItem);
					itemArrayNo.push(propItem.item);
					itemSelectedIds.push(propItem.id.toString());
				}
			}

			setLocalStorage(
				'project_proposal_edit_checked_item',
				itemSelectedIds,
				true
			);
		}

		if (items.length) {
			items = this.sortArray(items, (item) => {
				return item.id;
			});
		}

		for (let item of items) {
			itemActiveIds.push(item.id);
		}

		// Set active list including checked and unchecked
		setLocalStorage('addProposalSelected', itemActiveIds, true);

		data.itemNoArray = itemArrayNo;

		this.setDefaultData(data);

		this.setState({
			proposal: data,
			activeItems: items,
			projectItems: data.projectItems,
			projectItemsUnselected: data.projectItemsUnselected,
			dataIsLoaded: true,
		});
	}

	setDefaultData(proposal) {
		let data = {
			...this.state.defaultData,
			...{
				proj: proposal.projectCode,
				propname: proposal.propname || proposal.projectName,
				propdate: proposal.propdate
					? proposal.propdate
					: this.state.defaultData.propdate,
				adddepdt: proposal.adddepdt
					? proposal.adddepdt
					: this.state.defaultData.adddepdt,
				addDepAmt: proposal.adddep,
				showActDep: proposal.showactdep,
				propStyle: proposal.style,
				remarks: proposal.remarks ?? proposal.projectRemarks,
				remarksrtf: proposal.remarksrtf ?? proposal.projectRemarksRtf,
				itemNoArray: proposal.itemNoArray,
				reportFormatId: proposal.style ?? 0,
				requestedDeposit: proposal.requestedDeposit,
				receivedDeposit: proposal.receivedDeposit,
				lastPaymentDate: proposal.lastPaymentDate,
				lastNotificationId: proposal.lastNotificationId,
			},
		};

		if (this.props.params.pid) {
			data.replacePropNum = proposal.propnum;
		}

		this.setState({
			defaultData: data,
		});

		setLocalStorage(`project_proposal_edit`, data, true);
	}

	handleShowItems = (e) => {
		if (e.target.checked) {
			this.setState({
				activeItems: this.state.proposal.projectItems,
			});
		} else {
			this.setState({
				activeItems: this.state.proposal.availableItems,
			});
		}
	};

	handleMenuClick = (e) => {
		e.preventDefault();

		// Set the active menu and string.
		this.setState(
			(prevState) => (prevState.activeMenu = e.target.getAttribute('data-menu'))
		);
	};

	handleSaveClick = (e) => {
		setDraft('false');
		window.onbeforeunload = null;
		this.setIsLoading(true);
		const { id } = this.props.params;

		const projectId = id;

		const proposal = getLocalStorage(`project_proposal_edit`, true);
		proposal.itemNoArray = this.state.activeItems.map((item) => {
			return item.item;
		});

		const request = this.api.postJson('proposals', proposal);

		handleResponseAlert(request, (res) => {
			this.setIsLoading(false);
			if (res && res.id) {
				setTimeout(() => {
					window.location.href = generatePath(URI.project.proposal.list, {
						id: projectId,
					});
				}, 1500);
			}
		});
	};

	handleShowAllUnselectedItems = async (showAll) => {
		this.setState({
			showAllItems: showAll,
		});

		let queryFilters = `?$filter=proj eq '${this.state.initialProposal.projectCode}' and inactive eq false and completed eq false`;

		if (!showAll) {
			queryFilters += ' and propdate eq null';
		}

		const projectItems = await this.getProjectItemsByFilter(queryFilters);
		this.mapAndSetFilteredItems(projectItems);
	};

	mapAndSetFilteredItems = (projectItems) => {
		const isItemSelectable = (item) => {
			const initialProposal = this.state.initialProposal;
			const isItemInCurrentProposal = item.latestPrpId === initialProposal?.id;

			if (item.latestprpno && isItemInCurrentProposal) {
				const isInActiveItems = this.state.activeItems.find(
					(activeItem) => activeItem.id === item.id
				);
				//If item is already in this proposal and we are editing, we allow selection if it´s not already selected
				const isSelectable = !isInActiveItems;

				return isSelectable;
			}

			return this.state.projectOneproposal
				? !!item.latestprpno === false
				: true;
		};

		const filteredProjectItems = projectItems
			.filter((item) => {
				return !this.state.activeItems.find(
					(activeItem) =>
						activeItem.id === item.id && !item.inactive && !item.completed
				);
			})
			.map((item) => ({
				...item,
				isSelectable: isItemSelectable(item),
			}));
		return this.setState({
			projectItemsUnselected: filteredProjectItems,
		});
	};

	handleItemAddClick = (ids) => {
		const existingIds = getLocalStorage('addProposalSelected', true);
		let newIds = ids || [];

		const { projectItems } = this.state.proposal;
		let activeItems = [];
		let projectItemsUnselected = [];

		if (existingIds) {
			for (let id of existingIds) newIds.push(id);
		}

		setLocalStorage('addProposalSelected', newIds, true);
		setDraft('true');

		if (projectItems && projectItems.length) {
			activeItems = this.getProjectItemsByIds(newIds);
			projectItemsUnselected = this.getUnselectedProjectItemsByIds(newIds);
		}
		this.setState({
			activeItems,
			projectItemsUnselected,
		});
		this.handleShowAllUnselectedItems(this.state.showAllItems);
	};

	handleItemRemove = (ids) => {
		const existingIds = getLocalStorage('addProposalSelected', true);
		const existingCheckedIds = getLocalStorage(
			'project_proposal_edit_checked_item',
			true
		);

		let newActiveItem = [];
		let projectItemsUnselected = [];

		ids = [...existingCheckedIds].map((i) => parseInt(i));

		let newIds = this.removeIds(existingIds, ids);

		if (!ids.length) {
			displayAlert('danger', 'Please select at least one item', 2000);
			return;
		}

		if (newIds) {
			newActiveItem = this.getProjectItemsByIds(newIds);
			projectItemsUnselected = this.getUnselectedProjectItemsByIds(newIds);

			setLocalStorage('addProposalSelected', newIds, true);
			setDraft('true');
		}

		this.setState({
			activeItems: newActiveItem,
			projectItemsUnselected,
		});
		this.handleShowAllUnselectedItems(this.state.showAllItems);
	};

	handleDataChange = (data) => {
		this.updatedData[data.key] = data.value;
	};

	sortArray(toSort, callback) {
		return toSort.sort((a, b) => {
			return callback(a) - callback(b);
		});
	}

	removeIds(existingIds, ids) {
		let newIds = [];
		if (existingIds.length) {
			for (let id of existingIds) {
				let found = false;
				if (ids.length) {
					for (let id2 of ids) {
						if (id2 === id) found = true;
					}
				}

				// If id not found on selected
				if (!found) newIds.push(id);
			}
		}

		return newIds;
	}

	getProjectItemsByIds(ids) {
		const { projectItems } = this.state.proposal;

		let projItemsList = [];
		for (let item of projectItems) {
			let found = false;
			for (let id of ids) {
				if (id === item.id) found = true;
			}

			if (found) {
				projItemsList.push(item);
			}
		}

		return projItemsList;
	}

	getUnselectedProjectItemsByIds(ids) {
		const { projectItems } = this.state.proposal;

		let projItemsList = [];
		for (let item of projectItems) {
			let found = false;
			for (let id of ids) {
				if (id === item.id) found = true;
			}

			if (!found) {
				projItemsList.push(item);
			}
		}

		return projItemsList;
	}

	setIsLoading(state) {
		this.setState({
			isLoading: state,
		});
	}

	async handleTestDeleteObjects(e) {
		let workers = [];
		workers.push(
			await this.api.postJson('deleteobjects', {
				deleteType: 'dmriTestOnly',
				objectType: 'objProposal',
				objectCodeOrId: this.props.params.pid,
			})
		);

		if (workers) {
			HandleDeleteWorker(
				workers,
				{
					moduleSingular: 'Proposal',
					modulePlural: 'Proposals',
					reference: 'Projects',
					objectType: 'objProposal',
				},
				false,
				(modalState) => {
					this.setState(modalState);
				}
			);
		}
	}

	refreshDataAfterDelete = (e) => {
		this.hideModal();
		setTimeout(() => {
			window.onbeforeunload = null;
			window.location.href = getRouteWithParam(URI.project.viewPage, {
				id: this.props.params.id,
				page: 'proposals',
			});
		}, 500);
	};

	hideModal = (e) => {
		this.setState({
			showModal: false,
		});
	};

	filterItems(filters) {
		this.setState({
			filters: filters,
		});

		const filteredActiveItems = this.state.activeItems;

		tableSearch({
			data: filteredActiveItems,
			searchProps: filters,
			callback: async (data) => {
				this.setState({
					filteredActiveItems: data,
				});
			},
		});
	}

	sortItems(sorters) {
		this.setState({ sorters });

		const { filters, filteredActiveItems, activeItems } = this.state;
		const filtered = isFiltered(filters);
		const [sortKey, sortOrder] = sorters.split(' ');

		const sortedItems = this.sortActiveItems(
			filtered ? filteredActiveItems : activeItems,
			sortKey,
			sortOrder
		);

		if (filtered) {
			this.setState({ filteredActiveItems: sortedItems });
		} else {
			this.setState({ activeItems: sortedItems });
		}
	}

	sortActiveItems(items, sortKey, sortOrder) {
		return sortOrder === 'asc'
			? _.sortBy(items, sortKey)
			: _.sortBy(items, sortKey).reverse();
	}

	rendeHeader() {
		const { activeMenu, isLoading } = this.state;
		const projectId = this.props.params.id;

		return (
			<HeaderLight>
				<HeaderLight.Breadcumbs>
					<NavLink
						to={getRouteWithParam(URI.project.viewPage, {
							id: projectId,
							page: 'proposals',
						})}
						className="text-primary active d-flex align-items-center text-decoration-none fw-bold"
					>
						<i className="ri-arrow-left-s-line"></i> Back
					</NavLink>
					<Breadcrumb className="ms-4">
						<Breadcrumb.Item linkProps={{ to: URI.project.base }} linkAs={Link}>
							Projects
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkProps={{
								to: getRouteWithParam(URI.project.viewPage, {
									id: projectId,
									page: 'items',
								}),
							}}
							linkAs={Link}
						>
							{this.state.projectName}
						</Breadcrumb.Item>
						<Breadcrumb.Item
							linkProps={{
								to: getRouteWithParam(URI.project.viewPage, {
									id: projectId,
									page: 'proposals',
								}),
							}}
							linkAs={Link}
						>
							Proposals
						</Breadcrumb.Item>
						<Breadcrumb.Item>{this.title}</Breadcrumb.Item>
					</Breadcrumb>
				</HeaderLight.Breadcumbs>
				<HeaderLight.Content actions={true}>
					<HeaderLight.Title>{this.title}</HeaderLight.Title>

					<div>
						<Button
							variant="ivory"
							name="cancel"
							className="mx-3"
							href={getRouteWithParam(URI.project.viewPage, {
								id: projectId,
								page: 'proposals',
							})}
							onClick={() => {
								const preData = getLocalStorage('preData');
								if (preData !== null) {
									window.localStorage.removeItem('preData');
								}

								this.props.navigate(
									getRouteWithParam(URI.project.viewPage, {
										id: projectId,
										page: 'proposals',
									})
								);
							}}
						>
							Cancel
						</Button>
						{/* Submit Button */}
						<SecureBootstrapButton
							attributeNo={17}
							attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
							variant="primary"
							name="save"
							onClick={this.handleSaveClick.bind(this)}
							disabled={isLoading}
						>
							{getSaveButtonName(isLoading, 'Save')}
						</SecureBootstrapButton>
					</div>
				</HeaderLight.Content>
				<HeaderLight.Actions className="text-charcoal">
					<li>
						<Button
							as={Link}
							to={URI.project.add}
							data-menu="proposal"
							className={`${compareStr('proposal', activeMenu, 'active')}`}
							onClick={this.handleMenuClick}
						>
							Proposal
						</Button>
					</li>
					<li>
						<Button
							as={Link}
							to={URI.project.add}
							data-menu="remarks"
							className={`${compareStr('remarks', activeMenu, 'active')}`}
							onClick={this.handleMenuClick}
						>
							Remarks
						</Button>
					</li>
					{this.props.params.pid && this.props.params.pid > 0 && (
						<li>
							<Button
								as={Link}
								to={URI.project.add}
								data-menu="additional-deposit"
								className={`${compareStr(
									'additional-deposit',
									activeMenu,
									'active'
								)}`}
								onClick={this.handleMenuClick}
							>
								Additional Deposit Request
							</Button>
						</li>
					)}
				</HeaderLight.Actions>
			</HeaderLight>
		);
	}

	renderContent() {
		// Get the local storage first if available.
		let proposal = getLocalStorage(`project_proposal_edit`, true);
		proposal = Is.empty(proposal) ? this.state.proposal : proposal;
		proposal.propdate = dayjs(new Date()).format('YYYY-MM-DD');

		return getSubComponent(this.state.activeMenu, {
			proposal: (
				<ProjectProposalAddProposal
					showAllAvailableItems={this.state.showAllItems}
					statusCodes={this.state.statusCodes}
					proposal={proposal}
					proposalToEdit={this.state.proposalToEdit}
					proposalObj={this.state.proposal}
					proposalTypeDefault={this.state.proposalTypeDefault}
					activeItems={this.state.activeItems}
					filteredActiveItems={this.state.filteredActiveItems}
					projectItemsUnselected={this.state.projectItemsUnselected}
					showAllUnselectedItems={this.handleShowAllUnselectedItems.bind(this)}
					handleShowItems={(event) => this.handleShowItems(event)}
					addItemCall={this.handleItemAddClick.bind(this)}
					removeItemCall={this.handleItemRemove.bind(this)}
					filterItems={this.filterItems.bind(this)}
					sortItems={this.sortItems.bind(this)}
					dataIsLoaded={this.state.dataIsLoaded}
				/>
			),
			remarks: (
				<ProjectProposalAddRemarks
					proposal={proposal}
					onDataChange={this.handleDataChange}
					updatedData={this.updatedData}
				/>
			),
			'additional-deposit': (
				<ProjectProposalAddAdditionalDeposit
					proposal={proposal}
					activeItems={this.state.activeItems}
					dataIsLoaded={this.state.dataIsLoaded}
				/>
			),
		});
	}

	render() {
		const { isLoading, isNotFound } = this.state;
		return isNotFound ? (
			<NotFoundPage />
		) : (
			<>
				{this.rendeHeader()}

				<div className="content-padding min-height">
					<Container fluid>
						{this.state.dataIsLoaded ? (
							this.renderContent()
						) : (
							<Spinner isChild={true}></Spinner>
						)}
					</Container>
				</div>

				{/* Submit Button */}
				<FooterFormAction>
					<Row className="w-100">
						<Col lg={6} className="mb-3">
							{this.props.params.pid && this.props.params.pid > 0 && (
								<Button
									as={Link}
									onClick={this.handleTestDeleteObjects.bind(this)}
									variant="trans-light border-secondary-ash w-30"
									size="lg"
									disabled={isLoading}
								>
									{getSaveButtonName(isLoading, 'Delete')}
								</Button>
							)}
						</Col>
						<Col lg={6} className="align-right mb-3">
							<div className="d-flex justify-content-end">
								<Button
									as={Link}
									to={getRouteWithParam(URI.project.viewPage, {
										id: this.props.params.id,
										page: 'proposals',
									})}
									variant="trans-light border-secondary-ash"
									size="lg"
									className="mx-3"
								>
									Cancel
								</Button>
								<SecureBootstrapButton
									attributeNo={17}
									attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
									variant="primary"
									size="lg"
									onClick={this.handleSaveClick.bind(this)}
									disabled={isLoading}
								>
									{getSaveButtonName(isLoading, 'Save')}
								</SecureBootstrapButton>
							</div>
						</Col>
					</Row>
				</FooterFormAction>
				<DeleteModal
					refreshData={this.refreshDataAfterDelete.bind(this)}
					show={this.state.showModal}
					hideModal={this.hideModal}
					responses={this.state.deleteResponses}
				/>
			</>
		);
	}
}

export default WithRouter(ProjectProposalAdd);
