import { Component } from 'legacy/lib/api/types/Component';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import SubComponent from './Subcomponent';
import useCreateComponent from 'legacy/lib/api/hooks/useCreateComponent';
import { useParams } from 'react-router-dom';
import { displayAlert, displayAlertError } from 'legacy/utilities/Response';
import useDeleteObject from 'legacy/lib/api/hooks/useDeleteObject';
import { DeleteType } from 'legacy/lib/api/types/DeleteType';
import { DeleteObjectTypes } from 'legacy/lib/api/types/DeleteObjectTypes';
import ComponentsDeleteWarningModal from './ComponentsDeleteWarningModal';
import SecureBootstrapButton from 'legacy/app/components/security/SecureBootstrapButton';
import { SECURITY_ATTRIBUTE_TYPES } from 'legacy/app/context/security';
import Label from 'legacy/app/components/label/Label';
import { useFormContext } from 'react-hook-form';
import { CompNumericPercentType } from 'legacy/lib/api/types/CompPercentType';
import { TWhatChangedComponent } from 'legacy/lib/api/types/WhatChangedComponent';
import normalizeRecalculateDataPayload from '../utils/normalizeRecalculateDataPayload';
import convertObjectPropsToLowercase from 'legacy/utilities/convertObjectPropsToLowercase';
import normalizeRecalculateDataResponse from '../normalizeRecalculateDataResponse';
import { TFormValues } from '../types/TFormValues';
import { apiClient } from 'api/apiClient';
import useEditComponent from 'legacy/lib/api/hooks/useEditComponent';
import normaliseComponentPayload from 'legacy/utilities/normalizeComponentPayload';
import useGetProject from 'legacy/lib/api/hooks/useGetProject';

const SubComponents = ({
	components,
	multipleCompsRecalculating,
	setMultipleCompsRecalculating,
	isParentRecalculating,
	mainComponentComp,
	disableInputs,
	updateComponents,
}: {
	disableInputs: boolean;
	multipleCompsRecalculating: boolean;
	setMultipleCompsRecalculating: (newValue: boolean) => void;
	isParentRecalculating: boolean;
	mainComponentComp: string;
	components: Component[];
	updateComponents: (components: Component[]) => void;
}) => {
	const { id: projectId, itemId } = useParams();

	const { data: project } = useGetProject(projectId as string);

	const { mutate: createComponents, isLoading: isCreating } =
		useCreateComponent(Number(projectId), {
			onError: () =>
				displayAlertError(
					'There was an error creating the component, please try again'
				),
			onSuccess(data) {
				const compsCopy = structuredClone(components);
				compsCopy.push(data);
				updateComponents(compsCopy);
			},
		});

	const [cantDeleteMessage, setCantDeleteMessage] = useState('');
	const [deletedComponent, setDeletedComponent] = useState<Component | null>(
		null
	);
	const [showWarningModal, setShowWarningModal] = useState(false);

	const { mutateAsync, isLoading: isDeleting } = useDeleteObject();

	const componentsRef = useRef<Component[]>(components);

	const methods = useFormContext<TFormValues>();

	const estPrice = methods.watch('estprice');

	const supplier = methods.watch('supplier');

	const estPriceRef = useRef(estPrice);

	const supplierRef = useRef(supplier);

	const mainComponentUnitMeasure = methods.watch('unitmeasure');

	const { mutateAsync: editComponent } = useEditComponent();

	useEffect(() => {
		componentsRef.current = components;
	}, [components]);

	const recalculateMultipleComponents = useCallback(
		async (areFeeComponents?: boolean) => {
			const componentsRefCopy = [...componentsRef.current];
			let i = 0;

			setMultipleCompsRecalculating(true);
			for (const component of componentsRefCopy) {
				if (areFeeComponents) {
					if (component.usedisc === CompNumericPercentType.Fee) {
						try {
							const recalculatedData = await apiClient.post(
								`/components/${component.id}/recalculate`,
								{
									whatChanged: TWhatChangedComponent.cwcEstimatedCost,
									...normalizeRecalculateDataPayload({
										...component,
										feecost: Number(estPrice),
									}),
								}
							);

							componentsRefCopy[i] = {
								...component,
								...(convertObjectPropsToLowercase(
									normalizeRecalculateDataResponse(recalculatedData.data)
								) as Component),
							};
						} catch {
							// do nothing for now
						}
					}
					i++;
				} else {
					try {
						const recalculatedData = await apiClient.post(
							`/components/${component.id}/recalculate`,
							{
								whatChanged: TWhatChangedComponent.cwcVendor,
								...normalizeRecalculateDataPayload({
									...component,
									supplier,
								}),
							}
						);

						const normalizedRecalculatedData = normalizeRecalculateDataResponse(
							recalculatedData.data
						);

						await editComponent(
							normaliseComponentPayload({
								...component,
								id: component.id,
								supplier,
								supdep: normalizedRecalculatedData.supDep,
								bterm1: normalizedRecalculatedData.bTerm1,
								bterm2: normalizedRecalculatedData.bTerm2,
								bterm3: normalizedRecalculatedData.bTerm3,
								comptype: normalizedRecalculatedData.compType,
								markup: normalizedRecalculatedData.markup,
								useterms: normalizedRecalculatedData.useTerms,
							})
						);

						componentsRefCopy[i] = {
							...component,
							...(convertObjectPropsToLowercase(
								normalizeRecalculateDataResponse(recalculatedData.data)
							) as Component),
						};
					} catch {
						// do nothing for now
					}
					i++;
				}
			}
			updateComponents(componentsRefCopy);
			setMultipleCompsRecalculating(false);
		},
		[
			estPrice,
			updateComponents,
			supplier,
			editComponent,
			setMultipleCompsRecalculating,
		]
	);

	useEffect(() => {
		if (estPriceRef.current === undefined) {
			estPriceRef.current = estPrice;
			return;
		} else if (
			estPrice !== estPriceRef.current &&
			!isParentRecalculating &&
			!multipleCompsRecalculating
		) {
			estPriceRef.current = estPrice;
			recalculateMultipleComponents(true);
		}
	}, [
		estPrice,
		recalculateMultipleComponents,
		isParentRecalculating,
		multipleCompsRecalculating,
	]);

	useEffect(() => {
		if (supplierRef.current === undefined) {
			supplierRef.current = supplier;
			return;
		} else if (
			supplier !== supplierRef.current &&
			!isParentRecalculating &&
			!multipleCompsRecalculating
		) {
			supplierRef.current = supplier;
			recalculateMultipleComponents();
		}
	}, [
		supplier,
		recalculateMultipleComponents,
		isParentRecalculating,
		multipleCompsRecalculating,
	]);

	const updateComponent = useCallback(
		(component: Component) => {
			const compsCopy = structuredClone(componentsRef.current);

			const oldCompIndex = compsCopy.findIndex(
				(comp: Component) => component.id === comp.id
			);
			compsCopy[oldCompIndex] = component;
			updateComponents(compsCopy);
		},
		[updateComponents]
	);

	const handleComponentDelete = async (component: Component) => {
		try {
			const canBeDeleted = await mutateAsync({
				deleteType: DeleteType.dmriTestOnly,
				objectType: DeleteObjectTypes.objComponent,
				objectCodeOrId: component.id,
			});

			if (canBeDeleted.fullDeletePossible) {
				await mutateAsync({
					deleteType: DeleteType.dmriTestAndFullDeleteIfPossible,
					objectType: DeleteObjectTypes.objComponent,
					objectCodeOrId: component.id,
				});

				const compsCopy = structuredClone(componentsRef.current);
				const filteredComps = compsCopy.filter(
					(comp: Component) => comp.id !== component.id
				);
				displayAlert('success', 'Component deleted successfully!');
				return updateComponents(filteredComps);
			}

			setCantDeleteMessage(canBeDeleted.message);
			setShowWarningModal(true);
		} catch {
			setCantDeleteMessage('Network error, please try again');
			setShowWarningModal(true);
		}
	};

	const handleCreateComponent = () => {
		const createComponentPayload = {
			itemId: Number(itemId),
			componentType: 0,
			associatedComponentNumber: mainComponentComp,
			createAutoComponents: false,
			setDefaultsFromItem: true,
		};
		createComponents({
			createComponentPayload,
			addDefaultDescription: true,
			unitMeasure: mainComponentUnitMeasure,
			supplier,
		});
	};

	return (
		<div className="tw-p-4">
			<ComponentsDeleteWarningModal
				message={cantDeleteMessage}
				show={showWarningModal}
				component={deletedComponent as Component}
				confirmAction={() => {
					setShowWarningModal(false);
					setTimeout(() => setCantDeleteMessage(''), 500);
				}}
			/>
			<div className="tw-flex tw-justify-between tw-items-center tw-p-5 tw-bg-[#EEEDE9]">
				<p className="tw-text-base tw-font-bold tw-m-0">
					Freight / Design Fee / Additional Charges
				</p>
				<SecureBootstrapButton
					attributeNo={14}
					attributeType={SECURITY_ATTRIBUTE_TYPES.DenyEdit}
					variant="primary"
					size="sm"
					onClick={handleCreateComponent}
					disabled={
						isCreating ||
						isDeleting ||
						components.length === 4 ||
						multipleCompsRecalculating ||
						isParentRecalculating ||
						!!project?.closeddt ||
						disableInputs
					}
				>
					<i className="ri-add-line" /> Add
				</SecureBootstrapButton>
			</div>
			<div className="tw-flex tw-flex-col tw-gap-4 tw-mt-4 tw-mb-4">
				{components.length > 0 && (
					<div className="tw-grid tw-grid-cols-6 tw-content-center tw-gap-4 tw-items-center">
						<div />
						<Label label="Cost" className="tw-font-bold xl:tw-text-left" />
						<Label label="% Value" className="tw-font-bold xl:tw-text-left" />
						<Label label="Price" className="tw-font-bold xl:tw-text-left" />
						<div />
						<div />
						{components.map((comp) => (
							<SubComponent
								mainComponentComp={mainComponentComp}
								disableInputs={
									isDeleting ||
									isCreating ||
									multipleCompsRecalculating ||
									isParentRecalculating ||
									disableInputs
								}
								setDeletedComponent={(component) => {
									setDeletedComponent(component as Component);
									handleComponentDelete(component as Component);
								}}
								component={comp}
								updateComponent={updateComponent}
								key={comp.id}
							/>
						))}
					</div>
				)}
			</div>
		</div>
	);
};
SubComponents.displayName = 'SubComponents';
export default SubComponents;
