import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { ApiService } from 'legacy/lib/api/HttpService';
import { Summary } from 'legacy/lib/api/types/Summary';
import { uniqBy } from 'lodash';
import BaseDropdown, { TBaseDropdownProps } from './BaseDropdown';

const api = new ApiService();

const getLocations = async (projectId?: string) => {
	const locations = !projectId
		? ((await api.getLocationsSummaries()) as Summary[])
		: ((await api.getLocationsSummaries(
				`?$filter=(projectId eq null or projectId eq ${encodeURIComponent(
					projectId
				)})&$orderby=locn asc`
		  )) as Summary[]);
	const uniqueLocations = uniqBy(locations, (obj) => obj.key);
	return uniqueLocations;
};

const LocationDropdown = (
	props: TBaseDropdownProps & {
		projectId?: string;
	}
) => {
	const { data, isLoading } = useQuery({
		queryKey: ['dropdowns', 'locationsSummaries', props.projectId],
		queryFn: () => getLocations(props.projectId),
		refetchOnWindowFocus: false,
		refetchInterval: false,
	});

	const options =
		data?.map((option) => ({
			label: `${option.value ?? ''} ${
				option.key ? `[${option.key}]` : ''
			}`.trim(),
			value: option.key,
		})) || [];

	return <BaseDropdown {...props} isLoading={isLoading} options={options} />;
};

LocationDropdown.displayName = 'LocationDropdown';

export default LocationDropdown;
