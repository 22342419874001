import React, { useEffect, useState } from 'react';
import usePaginatedQuery from 'legacy/lib/api/hooks/usePaginatedQuery';
import BaseSelect from 'legacy/app/components/Selects/BaseSelect';
import { TStockItem } from 'legacy/lib/api/types/StockItem';
import { getInventoryStocks } from 'api/resources/inventoryStocks';
import { displayAlert } from 'legacy/utilities/Response';
import { EAlertTypes } from 'legacy/app/enums/alertTypes/alertTypes';
import classNames from 'classnames';

interface IInventorySelectProps {
	handleSelect: (item: TStockItem | null) => void;
	defaultSelected?: string;
}

export const InventorySelect = ({
	handleSelect,
	defaultSelected,
}: IInventorySelectProps) => {
	const defaultFilter = '?$filter=inactive eq false&$orderby=stockno asc';
	const [isDefaultSet, setIsDefaultSet] = useState(false);
	const [defaultStockNo, setDefaultStockNo] = useState<TStockItem | null>(null);

	const {
		data: inventoryItems,
		updateFilter,
		loadMore,
		hasMore,
		isFetching,
	} = usePaginatedQuery<TStockItem>(async (filter: string) => {
		return await getInventoryStocks(filter, true);
	}, defaultFilter);

	const optionFormatter = (item: TStockItem) => {
		return {
			value: `${item.stockno} ${item.itemName}`,
			label: (
				<span
					className={classNames({
						'tw-flex tw-justify-between': item?.stockImage,
					})}
				>
					{item.itemName} {`[${item.stockno}]`}
					{item.primaryImageId && item?.stockImage && (
						<img
							src={item.stockImage && URL.createObjectURL(item.stockImage)}
							className="tw-w-5 tw-h-5"
							alt={item.itemName}
						/>
					)}
				</span>
			),
			data: item,
		};
	};

	const handleInputFilter = (input: string) => {
		return `?$filter=(inactive eq false) and (contains(itemName,'${encodeURIComponent(
			input
		)}') or contains(stockno,'${encodeURIComponent(input)}'))`;
	};

	const handleSelectedOption = (
		option: { value: string; data: TStockItem } | null
	) => {
		return option?.data || null;
	};

	useEffect(() => {
		const fetchDefaultStockno = async () => {
			try {
				if (defaultSelected && !isDefaultSet) {
					const filter = `?$filter=inactive eq false and stockno eq '${defaultSelected}'`;
					const [fetchedDefaultStockno] = await getInventoryStocks(
						filter,
						true
					);

					if (fetchedDefaultStockno) {
						const formattedDefaultOption = optionFormatter(
							fetchedDefaultStockno
						);
						setDefaultStockNo(fetchedDefaultStockno);
						handleSelect(formattedDefaultOption.data);
						setIsDefaultSet(true);
					}
				}
			} catch (error: unknown) {
				const responseError = error as {
					response?: { data?: { userError?: string } };
				};
				displayAlert(
					EAlertTypes.Danger,
					responseError.response?.data?.userError || 'Unknown error',
					7000
				);
			}
		};

		if (defaultSelected && !isDefaultSet) {
			fetchDefaultStockno();
		}
	}, [defaultSelected, handleSelect, isDefaultSet]);

	return (
		<BaseSelect<TStockItem>
			handleSelect={handleSelect}
			placeholder="Select Stock No."
			results={inventoryItems || []}
			updateFilter={updateFilter}
			loadMore={loadMore}
			handleInputFilter={handleInputFilter}
			handleSelectedOption={handleSelectedOption}
			optionFormatter={optionFormatter}
			hasMore={hasMore}
			isFetching={isFetching}
			defaultFilter={defaultFilter}
			defaultSelected={defaultStockNo}
		/>
	);
};

InventorySelect.displayName = 'InventorySelect';
