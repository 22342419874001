import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useCheckbookReconcile } from './hooks/useCheckbookReconcile';
import cn from 'classnames';

interface ICheckbookReconcileTotalsItemProps {
	heading: string;
	value: string;
	borderClass?: string;
}

const CheckbookReconcileTotalsItem = ({
	heading,
	value,
	borderClass = '',
}: ICheckbookReconcileTotalsItemProps) => {
	const { isTotalsLoading } = useCheckbookReconcile();
	return (
		<div
			className={cn(
				'tw-grid tw-grid-flow-row tw-gap-2 tw-justify-between tw-p-4 tw-border tw-border-[#dedcd7] tw-rounded-md',
				borderClass
			)}
		>
			<span>{heading}</span>
			{isTotalsLoading ? (
				<span className="tw-text-base tw-text-center">
					<Spinner variant="primary" size="sm" animation="grow" />
				</span>
			) : (
				<span className="tw-text-base tw-font-bold">{value}</span>
			)}
		</div>
	);
};

CheckbookReconcileTotalsItem.displayName = 'CheckbookReconcileTotalsItem';

export { CheckbookReconcileTotalsItem };
