import React from 'react';
import URI from 'legacy/defaults/RoutesDefault';
import { useHeader } from 'legacy/hooks/useHeader';
import { Button } from 'react-bootstrap';

export const CreditCardOverviewHeader = () => {
	useHeader({
		title: 'Credit Card',
		breadcrumbs: [
			{
				text: 'Accounts Payable',
				to: URI.accountsPayable.creditCard.base,
			},
			{
				text: 'Credit Card',
			},
		],
		actions: [
			<Button
				key="btn-reconcile-cta"
				variant="light"
				className="text-secondary-green"
				size="lg"
				href={URI.accountsPayable.creditCard.reconcile}
			>
				Reconcile
			</Button>,
		],
		enableBackButton: false,
	});
	return <></>;
};

CreditCardOverviewHeader.displayName = 'CreditCardOverviewHeader';
