import React, { FC } from 'react';
import { Table } from 'react-bootstrap';
import { showEmpty } from 'legacy/helpers/Loading';

export const WarehouseTable: FC<{
	data: any[];
}> = ({ data }) => {
	return (
		<div>
			<h6 className="fw-bold mb-2">Warehouse Quantity</h6>
			<div
				className="table-gradient overflow-y-scroll overflow-x-hidden"
				style={{ height: '260px' }}
			>
				<Table striped responsive className="a-table">
					<thead>
						<tr key="0" className="a-table-heading">
							<th>Code</th>
							<th>Name</th>
							<th>On Hand</th>
							<th>Committed</th>
						</tr>
					</thead>
					<tbody>
						{data.length
							? data.map((item: any, index) => (
									<tr key={index}>
										<td>{item.wh}</td>
										<td>{item.whName}</td>
										<td>{item.onhandadj}</td>
										<td>{item.commitadj}</td>
									</tr>
							  ))
							: showEmpty()}
					</tbody>
				</Table>
			</div>
		</div>
	);
};
WarehouseTable.displayName = 'WarehouseTable';
