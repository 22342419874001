import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useCheckbookReconcile } from './hooks/useCheckbookReconcile';
import ConfirmationModal from 'legacy/app/components/modal/ConfirmationModal';
import { usePostRunReports } from 'api/hooks/usePostRunReports';
import {
	IGetRunReportResponse,
	IPostRunReportsParams,
} from 'api/resources/runReports';
import { useSession } from 'legacy/app/context/session';
import { useGetRunReports } from 'api/hooks/useGetRunReports';
import { getCookie } from 'legacy/utilities/Auth';
import { usePostPublicMappings } from 'api/hooks/usePostPublicMappings';
import {
	displayAlertError,
	displayAlertLoader,
	hideAlertLoader,
} from 'legacy/utilities/Response';

const MAX_RETRIES = 10;
const RETRY_INTERVAL_MS = 1000;
let retryCount = 0;

const CheckbookReconcileReport = () => {
	const { usercode, userId } = useSession();
	const instanceId = getCookie('dmInstanceId');
	const { checkbook, totals, showPrintModal, closePrintModal } =
		useCheckbookReconcile();
	const [showCleared, setShowCleared] = useState(false);
	const [reportId, setReportId] = useState<string | null>(null);
	const { mutateAsync: postRunReports } = usePostRunReports();
	const { mutateAsync: postPublicMappings } = usePostPublicMappings();

	useGetRunReports(reportId ?? '', {
		enabled: !!reportId,
		refetchIntervalInBackground: false,
		refetchInterval: (data) => {
			const [response] = data || [];
			if (response?.status === 'Pending') {
				retryCount++;
				return retryCount < MAX_RETRIES ? RETRY_INTERVAL_MS : false;
			}
			if (response?.status === 'Complete') {
				retryCount = 0;
			}
			if (response?.status === 'Error') {
				displayError();
			}

			return false;
		},
		onSuccess: async (data) => {
			await handleOnGetReportSucces(data);
		},
		onError: () => displayError(),
	});

	const handleOnGetReportSucces = async (
		runReportResponse: IGetRunReportResponse[]
	) => {
		const [firstReportResponse] = runReportResponse;
		const { status, completedFileId: fileId } = firstReportResponse;
		if (status === 'Complete' && fileId) {
			try {
				const { link } = await postPublicMappings({
					fileId,
					label: 'Checkbook Reconciliation',
				});
				if (link) {
					window.open(link, '_blank');
				}
			} catch {
				displayError();
			} finally {
				hideAlertLoader();
			}
		}
	};

	const displayError = () => {
		displayAlertError('Could not generate the report. Try again later.');
	};

	const onPrintConfirm = async () => {
		if (!checkbook || !totals) {
			return;
		}

		const value = [
			checkbook.cashAccount,
			checkbook.statementDate,
			checkbook.endingBalance,
			totals.balance,
			totals.computerBalance,
			showCleared,
			usercode,
		].join(',');

		try {
			const payload: IPostRunReportsParams = {
				reportId: 1174,
				reportFormatId: 1350,
				reportObjectType: 'Report',
				instanceId,
				userId,
				objectId: null,
				runReportParameters: [
					{
						parameterId: 734,
						value,
					},
				],
			};

			displayAlertLoader('Please wait for your report to be generated.');
			const { id } = await postRunReports(payload);
			if (id) {
				setReportId(id);
			}
		} catch {
			displayAlertError('Could not generate the report. Try again later.');
		}
	};

	return (
		<ConfirmationModal
			confirmAction={onPrintConfirm}
			show={showPrintModal}
			toggleModal={closePrintModal}
			title="Checkbook Reconciliation Report"
			okBtnStyle="primary"
			labelOK="Ok"
			labelCancel="Cancel"
		>
			<div className="tw-grid tw-place-content-center">
				<Form.Check
					type="checkbox"
					id="show-cleared"
					label="Show Cleared Entries?"
					className="rounded"
					onChange={(e) => setShowCleared(e.target.checked)}
				/>
			</div>
		</ConfirmationModal>
	);
};

CheckbookReconcileReport.displayName = 'CheckbookReconcileReport';

export { CheckbookReconcileReport };
