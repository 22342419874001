import React, { useState } from 'react';
import ConfirmationModal from 'legacy/app/components/modal/ConfirmationModal';
import { formatDate } from 'legacy/helpers/Date';
import {
	TEditFinanceCharge,
	TFinanceChargeAccount,
} from 'legacy/pages/accounts-payable/credit-card/reconcile/FinanceCharge/FinanceChargeTypes';
import { Form, Table, Button } from 'react-bootstrap';
import {
	formatChargesList,
	getAcctNameFromString,
} from 'legacy/pages/accounts-payable/credit-card/reconcile/FinanceCharge/FinanceChargeUtils';
import { IJournalEntry } from 'api/resources/financeCharges';
import { usePostFinanceCharges } from 'api/hooks/usePostFinanceCharges';
import dayjs from 'dayjs';

type TFinanceChargeOverviewProps = Omit<TEditFinanceCharge, 'newTxnum'> & {
	account: TFinanceChargeAccount;
	showModal: boolean;
	onCancel: () => void;
	onCreateEntry: () => void;
	onUpdateEntry: (account: TFinanceChargeAccount, amount: number) => void;
	onDeleteEntry: (accountId: TFinanceChargeAccount) => void;
	onVoidEntireCharge: () => void;
	onSubmit: () => void;
	statementDate: string;
	journalEntries: IJournalEntry[];
	canVoid: boolean;
	isDirty: boolean;
	savedDescription: string;
};

export const FinanceChargeOverview = ({
	account,
	showModal,
	onCancel,
	onCreateEntry,
	onUpdateEntry,
	onDeleteEntry,
	onVoidEntireCharge,
	onSubmit,
	statementDate,
	journalEntries,
	canVoid,
	isDirty,
	savedDescription,
}: TFinanceChargeOverviewProps) => {
	const [description, setDescription] = useState(
		savedDescription || `Srv/Fin Charges ${formatDate(statementDate)}`
	);

	const { mutate } = usePostFinanceCharges();

	return (
		<ConfirmationModal
			size="xl"
			confirmAction={async () => {
				const formattedDate = dayjs(statementDate).format('YYYY-MM-DD');
				const payload = {
					description,
					isCheckbook: false,
					account: account.value,
					statementEndingDate: formattedDate,
					financeChargesList: formatChargesList(journalEntries),
				};

				mutate(payload);

				onSubmit();
			}}
			disabledOK={!isDirty}
			show={showModal}
			onCancel={onCancel}
			title="Service/Finance Charges"
			labelOK="OK"
			okBtnStyle="success"
		>
			<div className="tw-mb-3">
				<div className="tw-inline-block">
					<Form.Label className="tw-mb-0 tw-mr-2">Description:</Form.Label>
				</div>
				<div className="tw-inline-block">
					<Form.Control
						type="text"
						name="description"
						onChange={(e) => setDescription(e.target.value)}
						value={description}
					/>
				</div>
				<div className="tw-float-right">
					<Button onClick={() => onCreateEntry()}>
						<i className="ri-add-line ri-lg"></i> Add
					</Button>
				</div>
			</div>

			<div className="tw-flex tw-mb-4">
				<Table striped bordered hover className="tw-w-full">
					<thead>
						<tr>
							<th>Acct No.</th>
							<th className="w-full">Account Name</th>
							<th>Amount</th>
							<th className="tw-text-center">Action</th>
						</tr>
					</thead>
					<tbody>
						{(journalEntries as IJournalEntry[])?.map((entry) => {
							return (
								<tr key={`entry-${entry.account}`}>
									<td className="tw-align-middle">
										<a
											title="Edit entry"
											className="tw-text-black tw-no-underline tw-cursor-pointer"
											onClick={() => {
												onUpdateEntry(
													{
														value: entry.account,
														label: entry.accountName as string,
													},
													entry.damt || 0
												);
											}}
										>
											<span className="tw-underline">{entry.account}</span>{' '}
											<i className="ri-pencil-fill fsx-15 tw-text-green-700"></i>
										</a>
									</td>
									<td className="tw-align-middle w-full">
										{getAcctNameFromString(entry?.accountName || '')}
									</td>
									<td className="tw-align-middle">{entry.damt}</td>
									<td className="tw-align-middle tw-text-center">
										<Button
											size="sm"
											variant="link"
											onClick={() =>
												onDeleteEntry({
													value: entry.account,
													label: entry.accountName as string,
												})
											}
											disabled={journalEntries.length === 1}
										>
											<i className="ri-close-line fsx-20 tw-text-red-500"></i>
										</Button>
									</td>
								</tr>
							);
						})}
						{journalEntries.length === 0 && (
							<tr>
								<td colSpan={4} className="tw-text-center">
									No entries
								</td>
							</tr>
						)}
					</tbody>
				</Table>
			</div>
			<div className="tw-grid tw-grid-cols-2">
				<div>
					{canVoid && (
						<Button
							onClick={() => onVoidEntireCharge()}
							variant="outline-danger"
						>
							Void Entire Charge
						</Button>
					)}
				</div>
				<div className="tw-flex tw-place-content-end">
					<div className="tw-flex tw-items-center tw-mr-2">
						<Form.Label className="tw-inline">
							Total Service/Finance Charges:
						</Form.Label>
					</div>
					<div className="tw-flex tw-items-center tw-mb-[0.4rem]">
						{(journalEntries as IJournalEntry[]).reduce(
							(accumulator, currentValue) =>
								accumulator + (currentValue?.damt ? currentValue?.damt : 0),
							0
						)}
					</div>
				</div>
			</div>
		</ConfirmationModal>
	);
};

FinanceChargeOverview.displayName = 'FinanceChargeOverview';
