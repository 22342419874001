import { useQuery } from '@tanstack/react-query';

import { getAccounts } from './api';
import { EAccountType } from 'legacy/pages/accounts-payable/credit-card/reconcile/AccountsDropdown';

export type TAccountData = {
	account: string;
	accountn: string;
	banknum: null | number;
	cashcloseddt: null | number;
	checknum: string;
	closedfmo: null | string;
	dept: string;
	fixedType: number;
	nextchecknum: string;
	payroll: boolean;
	ppbankname: null | string;
	ppfileloc: null | string;
	ppincludehc: boolean;
	ppincludevoids: boolean;
	specacct: number;
	supplier: string;
	type: number;
};

export function useAccounts(type = EAccountType.LIABILITY, useSummary = false) {
	return useQuery<TAccountData[], Error>(
		['credit-card', 'accounts', type, useSummary],
		(): Promise<TAccountData[]> =>
			getAccounts({ type, useSummary }) as Promise<TAccountData[]>
	);
}

export function useGetAccountName(type = EAccountType.LIABILITY) {
	const accountsQuery = useAccounts(type);

	return (account: string) => {
		if (accountsQuery.isLoading) return null;

		const accountData = accountsQuery.data?.find((a) => a.account === account);
		return accountData?.accountn || '';
	};
}
